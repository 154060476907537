var config = {
	config: [
		[
			{
				objectKey: 'model_name',
				widget: 'FormTextInput',
				text: 'Model Name',
				widgetProps: {
					  InputProps:{
						readOnly: true,
					  }
				}
			},
			{
				objectKey: 'approval_status',
				widget: 'FormTextInput',
				text: 'Approval Status',
				
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				objectKey: 'is_active',
				widget: 'FormSwitch',
				text: 'Active',
				
				widgetProps: {
					disabled: true
				},
			},
			{
				widget: 'Spacer',
			},
			{
				objectKey: 'approved_user',
				widget: 'FormTextInput',
				text: 'Approved User',
				
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				objectKey: 'approved_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Approved Time',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				objectKey: 'approved_user_comment',
				widget: 'FormMultilineInput',
				text: 'Approved Comments',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			}
		],
		[
			{
				objectKey: 'created_user',
				widget: 'FormTextInput',
				text: 'Created User',
				widgetProps: {
					InputProps:{ readOnly: true }
				}
			},
			{
				objectKey: 'created_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Created Time',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				objectKey: 'created_user_ip_address',
				widget: 'FormTextInput',
				text: 'IP Address',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				widget: 'Spacer',
			},
			{
				objectKey: 'updated_user',
				widget: 'FormTextInput',
				text: 'Updated User',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				objectKey: 'updated_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Updated Time',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			}
		]
	]
}


export {
	config
}