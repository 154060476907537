import React from 'react';
import { Route} from 'react-router-dom';	
import APIConfig from '../../APIConfig.js'
import ReportView from './Routes/ReportView.js';


function MainReportRouter() {
	
	var createDispatchers = {
		fetchReport: {
			url:  APIConfig.modelWiseReporting,
			preprocessor: function(_obj, data){
				_obj.url = _obj.url + _obj.model_name+'/';
				return _obj;
			}
		}
	}
	
	var createFetcher = [
		{
			url: APIConfig.approvedModels,
			key: 'models'
		}
	]

	return (
		<React.Fragment>
			<Route path="/ecl-computation/model-report/">
				<ReportView fetchingData={createFetcher} dispatcher={createDispatchers} />
			</Route>
		</React.Fragment>
	);
}

export {
	MainReportRouter
}
