import React from 'react';
import {config} from '../config/ModelDefinitionApprove';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  { Redirect } from 'react-router-dom';
import {notificationObj} from '../../Global/NotificationHandler.js';

const columns=[
	{ field: 'variable_id', headerName: 'Variable ID', width: 300},
	{ field: 'variable_name', headerName: 'Variable Name',  flex: 1	},
	{ field: 'variable_type', headerName: 'Variable Type',  width: 350 },
	{ field: 'variable_selected_yn', headerName: 'Selected Variable',  flex: 1},
	{ field: 'variable_selected_value', headerName: 'Variable Value', width: 300},
];

function ModelDefinitionApprove(props) {
  
	const [created, setCreated] = React.useState(false);
	const [apiConf, setApiRef] = React.useState({});
	
	var approveClick = React.useCallback(function(){

		var _d = apiConf.getData();		
		
		if(!_d.approval_status){
			notificationObj.showNotification('error','Select Approved status.');
			return;
		}
		
		if(!_d.approved_user_comment){
			notificationObj.showNotification('error','Approved comments cannot be blank.');
			return;
		}
		
		var obj = {
			"model_name": _d.model_name,
			"model_definition_name": _d.model_definition_name,
			"approved_user_comment": _d.approved_user_comment,
			"approval_status": _d.approval_status
		}
		
		props.dispatchRequest('approve', {data: obj} ).then(function(e){
			setCreated(true)
		});
		
	},[props, apiConf]);
	
	
	if(created){
		return (<Redirect to='/model-definition/approve-list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title ecl-header-title">
				Approve Model Definition
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/model-definition/approve-list/">
					Model Definition(Approve List)
					</Link>
					<Typography color="textPrimary">{props.formData && (props.formData.model_definition_name)}</Typography>
				</Breadcrumbs>
			</Typography>
			
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Definition Details
				</Typography>
				<FormBuilder {...config} data={props.formData} apiConf={apiConf} />
			</Card>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Definition Variables
				</Typography>
				
				<div className="ecl-grid-container">
					 <DataGrid rows={(props.rowData || [])} className="variable-grid" columns={columns} />
				</div>
			</Card>
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick={approveClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ModelDefinitionApprove);