import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { performLogin } from '../../data/globalData/slices/userSlice';
import APIConfig from '../../APIConfig';

const axios = require('axios');


function LoginController() {
	
	const [id , setId] = React.useState('');
	const [password , setPassword] = React.useState('');
	const [error , setError] = React.useState(null);
	const [passwordError , setPasswordError] = React.useState({});
	
	const handleLogin = async function(){
		
		axios.post(APIConfig.login, {
			username: id,
			password: password
		})
		.then(function (response) {
			if(response.data.key){
				performLogin(response.data.key);
			}
		})		
		.catch(function (error) {
			if(error.response.data.password){
				setPasswordError({
					helperText: error.response.data.password,
					error: true
				});
			}else{
				setPasswordError({});
			}
			
			if(error.response.data.non_field_errors){
				setError(error.response.data.non_field_errors);
			}else{
				setError(null);
			}
			
			console.log(error);
		});
		
	}
	
	const keyPress = function(e){
      if(e.keyCode === 13){
         handleLogin();
      }
   }
	
	return (
		<div className="full-container login-container">
			<div className="login-form-wrapper" onKeyDown={keyPress}>
				<div className="login-spacer">
					<Typography variant="h4">
						Login to EasyECL Suite
					</Typography>
				</div>
				<div className="login-spacer">
					<TextField id="outlined-basic" label="Username" variant="outlined" value={id} onChange={(e)=>{  setId(e.target.value) }}/>
				</div>
				<div className="login-spacer">
					<TextField id="outlined-basic" label="Password" variant="outlined" type="password" value={password} onChange={(e)=>{  setPasswordError({}); setPassword(e.target.value) }} {...passwordError}/>
				</div>
				<div className="login-spacer">
					<Button variant="contained" color="primary" onClick={handleLogin}>
						Login
					</Button>
				</div>
				{ ( error )  && <div className="login-error-div">{error}</div>}
			</div>
		</div>
	);
}

export {
	LoginController
}