import {FormTextInput} from './Widgets/FormTextInput.js';
import {FormFileInput} from './Widgets/FormFileInput.js';
import {FormDropDown} from './Widgets/FormDropDown.js';
import {FormDateTimeInput} from './Widgets/FormDateTimeInput.js';
import {FormMultilineInput} from './Widgets/FormMultilineInput.js';
import {FormSwitch} from './Widgets/FormSwitch.js';
import {FormRadio} from './Widgets/FormRadio.js';
import {Spacer} from './Widgets/Spacer.js';

var widgets = {
	FormTextInput,
	FormDropDown,
	FormDateTimeInput,
	FormMultilineInput,
	FormSwitch,
	Spacer,
	FormRadio,
	FormFileInput
}

export {
	widgets
}