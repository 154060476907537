import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function VariableDataGrid(props) {
	
	const [rowData , setData] = React.useState(props.rows);
	var variableMap = {};
	
	React.useEffect (function(){
		setData(props.rows);
		variableMap = {};
		(rowData || []).forEach(function(_r){
			variableMap[_r.id] = _r;
		});
	},[props.rows]);
	
	
	(rowData || []).forEach(function(_r){
		variableMap[_r.id] = _r;
	});
	
	
	const handleChange = function(e, _data, key){
		variableMap[_data.row.id][key] = e.currentTarget.value;
		_data.row[key] = e.currentTarget.value;
	}
	
	var columns=[
		{ field: 'variable_id', headerName: 'Variable ID', width: 300},
		{ field: 'variable_name', headerName: 'Variable Name',  flex: 1,
			editable: true
		},
		{ field: 'variable_type', headerName: 'Variable Type (Numeric - Positive/negative value, Categorical - MIN = 0, MAX = 20)',  flex: 1,
			renderCell: function(params){
				return (
					<RadioGroup aria-label="variable_type" className="ecl-column-radio" value={params.row.variable_type} onChange={(e)=>{ handleChange(e, params, 'variable_type') }}>
						<FormControlLabel value="numeric" control={<Radio />} label="Numeric" />
						<FormControlLabel value="categorical" control={<Radio />} label="Categorical" />
					</RadioGroup>
				)
			}
		},
	];
	
	
	const handleEditCellChangeCommitted = React.useCallback(
		({ id, field, props }) => {
			variableMap[id][field] = props.value;
		},
		[rowData],
	  );
	
	
	
	return (
		<DataGrid  className={props.className} rows={rowData} columns={columns} 
			onEditCellChangeCommitted={handleEditCellChangeCommitted} />
	);
}

export default VariableDataGrid;