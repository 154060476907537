var config = {
	config: [
		[
			{
				objectKey: 'model_name',
				widget: 'FormTextInput',
				text: 'Model Name',
				widgetProps: {
					disabled: true
				}
			},
            {
				objectKey: 'model_definition_name',
				widget: 'FormTextInput',
				text: 'Model Definition Name',
				widgetProps: {
					disabled: true
				}
			},
            {
				objectKey: 'model_type',
				widget: 'FormTextInput',
				text: 'Model Type'
			},		
            {
				objectKey: 'intercept_y_value',
				widget: 'FormTextInput',
				text: 'Intercept Y Value',
				tooltip: 'Any numeric value, positive or negative',
				widgetProps: {
					type: "number",
					
				}
			},					
			{
				objectKey: 'send_for_approval',
				widget: 'FormSwitch',
				text: 'Send for Approval',
				tooltip: 'Send for Approval will change approval status to Pending for approval',

			},
			// {
			// 	objectKey: 'is_active',
			// 	widget: 'FormSwitch',
			// 	text: 'Active'
			// },
			{
				widget: 'Spacer',
			},
			{
				objectKey: 'approved_user',
				widget: 'FormTextInput',
				text: 'Approved User',
				
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'approved_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Approved Time',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'approved_user_comment',
				widget: 'FormMultilineInput',
				text: 'Approved Comments',
				widgetProps: {
					disabled: true
				},
			}
		],
		[
			{
				objectKey: 'created_user',
				widget: 'FormTextInput',
				text: 'Created User',
				widgetProps: {
					disabled: true
				}
			},
			{
				objectKey: 'created_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Created Time',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'created_user_ip_address',
				widget: 'FormTextInput',
				text: 'IP Address',
				widgetProps: {
					disabled: true
				},
			},
			{
				widget: 'Spacer',
			},
			{
				objectKey: 'updated_user',
				widget: 'FormTextInput',
				text: 'Updated User',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'updated_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Updated Time',
				widgetProps: {
					disabled: true
				},
			}
		]
	]
}


export {
	config
}