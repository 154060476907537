import TextField from '@material-ui/core/TextField';
import React from 'react';

function FormMultilineInput(props) {
	
	const [value, setValue] = React.useState(props.defaultValue || '');

	React.useEffect(() => {
	   setValue(props.defaultValue || '');
	}, [props.defaultValue]);
	
	const handleChange = function(event){
		setValue(event.target.value);
		if(props.onUpdateValue){
			props.onUpdateValue(event.target.value, props.objectKey);
		}
	}
	
	
	var _errorObj = {};
	if(props.errorObj && props.toValidate){
		_errorObj = {
			error: true,
			helperText: props.errorObj.message
		}
	}else{
		_errorObj  = null;
	}

	return (
		<TextField
			id={(props.id || props.objectKey)}
			value = {value}
			multiline
			onChange = {handleChange}
			{...props.widgetProps}
			variant="filled" 
			{..._errorObj}
		></TextField>
	);
}

export {
	FormMultilineInput
}