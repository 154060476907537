import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

function FormRadio(props) {
	
	const [value, setValue] = React.useState(props.defaultValue || '');

	React.useEffect(() => {
	   setValue(props.defaultValue || false);
	}, [props.defaultValue]);

	const handleChange = React.useCallback(function(event){
		setValue(event.target.value);
		if(props.onUpdateValue){
			props.onUpdateValue(event.target.value, props.objectKey);
		}
	},[props, setValue]);
  
	
	const FormElements = React.useMemo(function(data){
		
		return props.data.map(function(_d, idx){
			if(_d.tooltip){
				var _label = (
					<React.Fragment>
						{_d.text}<Tooltip title={_d.tooltip}><InfoIcon className="tooltip-icon" /></Tooltip>
					</React.Fragment>
					)
				
				return (<FormControlLabel className={'tooltip-form'} key={idx} value={_d.value} control={<Radio />} label={_label} />)
			}
			
			return (
				<FormControlLabel key={idx} value={_d.value} control={<Radio />} label={_d.text} />
			);
		});
		
	},[props.data]);
	

	return (
		<RadioGroup value={value} onChange={handleChange}>
			{FormElements}
		</RadioGroup>
	);
}

export {
	FormRadio
}