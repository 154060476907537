import React from 'react';
import {config} from '../config/ModelMasterApprove.js';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  { Redirect } from 'react-router-dom';
import {notificationObj} from '../../Global/NotificationHandler.js';

function ModelMasterApprove(props) {
	
	const [apiConf, setApiRef] = React.useState({});
	const [created, setCreated] = React.useState(false);
	const formBuilderRef = {};
	var _data = (props.data || []);
	
	var columns=[
		{ field: 'variable_name', headerName: 'Variable Name',  flex: 1	},
		{ field: 'variable_type', headerName: 'Variable Type',  width: 350 },
	];
	
	var approveClick = React.useCallback(function(){
		
		var _d = apiConf.getData();
		
		if(!_d.approved_user_comment){
			notificationObj.showNotification('error','Approved comments cannot be blank');
			return;
		}
		
		var obj = {
			 "model_name": _d.model_name,
			"approved_user_comment": _d.approved_user_comment,
			"approval_status": _d.approval_status
		}
		props.dispatchRequest('approve', {data: obj} ).then(function(e){
			setCreated(true)
		});
		
	},[props]);
	
	if(created){
		return (<Redirect to='/model-master/approve-list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title ecl-header-title">
				Approve Model Master
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/model-master/approve-list/">
					Master Model (Approve List)
					</Link>
					<Typography color="textPrimary">{_data[0] && (_data[0].model_name)}</Typography>
				</Breadcrumbs>
			</Typography>
			
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Details
				</Typography>
				<FormBuilder {...config} data={_data[0]} apiConf={apiConf} />
			</Card>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Variables
				</Typography>
				
				<div className="ecl-grid-container">
					 <DataGrid rows={(_data[1] || [])} className="variable-grid" columns={columns} />
				</div>
			</Card>
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick={approveClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ModelMasterApprove);