import React from 'react';
import {config} from '../config/ComputationView.js';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

function ComputationView(props) {
  
	var _data = (props.data || []);
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title ecl-header-title">
				View Configuration
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/ecl-computation/list">
					Configuration (All List)
					</Link>
					<Typography color="textPrimary">{_data[0] && (_data[0].configuration_name)}</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Configuration
				</Typography>
				<FormBuilder {...config} data={_data[0]} onUpdateForm = {(e)=> {_data[0]=e} }/>
			</Card>
		</div>
	);
}

export default dataUIWrapper(ComputationView);