import React from 'react';
// import {config} from '../config/ReportView';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import ModelChartReport from '../common/ModelChartReport.js'


function DashboardView(props) {
	
	const [modelsData, setModelsData] = React.useState(null);
	var _data = (props.data || []);
	console.log("data", _data);
	// console.log(modelsData);
	// const [modelName, setModelName] = React.useState('');
	
	
	// React.useEffect(function(){
	// 	if(props.models){
	// 		config.modelNameObj.data = props.models.map(function(_model){
	// 			return {
	// 				text: _model.model_name,
	// 				value: _model.model_name
	// 			}
	// 		});
	// 	}
	// },[props.models]);
	
	
	// const updateForm = function(key, value){	
	// 	// setModelName(value);
	// 	// if(key === 'model_name'){		
	// 	props.dispatchRequest('fetchReport').then(function(e){
	// 			setModelsData(e.data);
	// 	});			
	// 	// }
	// }
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Dashboard
			</Typography>
			{/* <Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Select Model to View Report
				</Typography>
				<FormBuilder onUpdate = {updateForm } />
			</Card> */}
			{
				( _data &&	<ModelChartReport modelsData={_data} reportsData={_data.scenario_completed}/>)
				
			}
		</div>
	);
}

export default dataUIWrapper(DashboardView);