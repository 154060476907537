import React from 'react';
import {config} from '../config/ModelMasterCreate.js';
import {variableMasterData} from '../data/variableMasterData.js';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import VariableDataGrid from '../common/VariableDataGrid.js';
import {notificationObj} from '../../Global/NotificationHandler.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  { Redirect } from 'react-router-dom';

var getVariableData = function(){
	return variableMasterData.slice().map(function(obj){
		return {
			...obj
		}
	});
}
function ModelMasterCreate(props) {
	
	const [apiConf, setApiRef] = React.useState({});
	const [rowData, setRowData] = React.useState(getVariableData());
	const [created, setCreated] = React.useState(false);
	
	const formData = {send_for_approval: false};
	
	
	const createClick = React.useCallback(function(){
		
		var errorMsg = '';
		
		var formData = apiConf.getData();
		
		var obj= {
			... formData,
			variables: rowData.map(function(_r){
				if(!errorMsg){
					if((_r.variable_name && !_r.variable_type)){
						errorMsg = 'Enter variable type for defined variable name';
					}else if((!_r.variable_name && _r.variable_type)){
						errorMsg = 'Enter variable name for defined variable type';						
					}
				}
				
				return {
					variable_id: _r.variable_id,
					variable_name: _r.variable_name,
					variable_type: _r.variable_type
				}
			})
			
		}
		
		if(errorMsg){
			notificationObj.showNotification('error',errorMsg);
			return;
		}
		
		props.dispatchRequest('create', {data: obj} ).then(function(){
			setCreated(true); 
		});
		
	},[props, apiConf, rowData])
	
	if(created){
		return (<Redirect to='/model-master/list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Create Master Model
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/model-master/list/">
					Master Model (All List)
					</Link>
					<Typography color="textPrimary">Create</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Master Model
				</Typography>
				<FormBuilder apiConf={apiConf} {...config}  data = { formData } />
			</Card>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Variables
				</Typography>
				
				<div className="ecl-grid-container">
					 <VariableDataGrid  className="variable-grid" rows={rowData} />
				</div>
			</Card>
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick = {createClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ModelMasterCreate);