import TextField from '@material-ui/core/TextField';
import React from 'react';

function FormFileInput(props) {
	
	const [value, setValue] = React.useState(props.defaultValue || '');

	React.useEffect(() => {
	   setValue(props.defaultValue || '');
	}, [props.defaultValue]);
	
	const handleChange = function(event){
		setValue(event.target.value);
		var files = event.target.files[0];
		if(props.onUpdateValue){
			props.onUpdateValue(files, props.objectKey);
		}
	}
	
	var _errorObj = {};
	if(props.errorObj && props.toValidate){
		_errorObj = {
			error: true,
			helperText: props.errorObj.message
		}
	}
	var inputProps = {
		... (props.widgetProps?props.widgetProps.inputProps:{}),
		type: 'file',
		accept: '.csv'
	}

	return (
		<TextField
			id={(props.id || props.objectKey)}
			//value = {value}
			type={'file'}
			onChange = {handleChange}
			{...props.widgetProps}
			inputProps={inputProps}
			variant="filled"
			{..._errorObj}			
		></TextField>
	);
}

export {
	FormFileInput
}