var config = {
	config: [
		[
			{
				objectKey: 'model_name',
				widget: 'FormTextInput',
				text: 'Model Name',
			}
		],
		[
			{
				objectKey: 'send_for_approval',
				widget: 'FormSwitch',
				text: 'Send for Approval',
				tooltip: 'Send for Approval will change approval status to Pending for approval',
			}
		]
	]
}


export {
	config
}