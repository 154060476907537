import React from 'react';
import {config} from '../config/ModelDefinitionView.js';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { DataGrid } from '@material-ui/data-grid';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const columns=[
	{ field: 'variable_id', headerName: 'Variable ID', width: 300},
	{ field: 'variable_name', headerName: 'Variable Name',  flex: 1	},
	{ field: 'variable_type', headerName: 'Variable Type',  flex: 1 },
	{ field: 'variable_selected_yn', headerName: 'Selected Variable',  flex: 1},
	{ field: 'variable_selected_value', headerName: 'Variable Value', width: 300},
];

function ModelDefinitionView(props) {
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				View Model Definition
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/model-definition/list/">
					Model Definition(All List)
					</Link>
					<Typography color="textPrimary">{props.formData && (props.formData.model_definition_name)}</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Definition Variable
				</Typography>
				<FormBuilder {...config} data={props.formData}/>
			</Card>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Definition Variables
				</Typography>
				<div className="ecl-grid-container">
					 <DataGrid rows={(props.rowData || [])} className="variable-grid" columns={columns} />
				</div>
			</Card>
		</div>
	);
}

export default dataUIWrapper(ModelDefinitionView);