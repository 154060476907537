var config = {
	config: [
		[
			{
				objectKey: 'configuration_name',
				widget: 'FormTextInput',
				text: 'Configuration Name',
				widgetProps: {
					  InputProps:{
						readOnly: true,
                        //disabled: false
					  }
				}
			},
            {
				objectKey: 'scenario_definition_name',
				widget: 'FormTextInput',
				text: 'Scenario definition Name',
				widgetProps: {
					  InputProps:{
						readOnly: true,
                        //disabled: false
					  }
				}
			},
            {
				objectKey: 'reporting_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Reporting Date',
				widgetProps: {
					InputProps:{ readOnly: true,disabled: false }
				},
			},					
			{
				objectKey: 'csv_file_name',
				widget: 'FormTextInput',
				text: 'CSV File Name',
				
				widgetProps: {
					InputProps:{ readOnly: true,disabled: false }
				},
			},
			{
				widget: 'Spacer',
			},
            {
				objectKey: 'created_user',
				widget: 'FormTextInput',
				text: 'Created User',
				widgetProps: {
					InputProps:{ readOnly: true, disabled: false }
				}
			},
			{
				objectKey: 'created_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Created Time',
				widgetProps: {
					InputProps:{ readOnly: true,disabled: false }
				},
			},
			{
				objectKey: 'created_user_ip_address',
				widget: 'FormTextInput',
				text: 'IP Address',
				widgetProps: {
					InputProps:{ readOnly: true, disabled: false }
				},
			},
			
		],
		[
            {
				objectKey: 'Job_status',
				widget: 'FormTextInput',
				text: 'Job Status',
				widgetProps: {
					InputProps:{ readOnly: true, disabled: false }
				},
			},
			{
				objectKey: 'Job_comments',
				widget: 'FormMultilineInput',
				text: 'Job Comments',
				widgetProps: {
					InputProps:{ readOnly: true,disabled: false }
				},
			},
            {
				objectKey: 'Job_Starttime',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Job Start Time',
				widgetProps: {
					InputProps:{ readOnly: true, disabled: false }
				},
			},
            {
				objectKey: 'Job_Completedtime',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Job Completion Time',
				widgetProps: {
					InputProps:{ readOnly: true, disabled: false }
				},
			},
			{
				widget: 'Spacer',
			},
			// {
			// 	objectKey: 'updated_user',
			// 	widget: 'FormTextInput',
			// 	text: 'Updated User',
			// 	widgetProps: {
			// 		InputProps:{ readOnly: true }
			// 	},
			// },
			// {
			// 	objectKey: 'updated_time',
			// 	widget: 'FormDateTimeInput',
			// 	preprocessors:'date',
			// 	text: 'Updated Time',
			// 	widgetProps: {
			// 		InputProps:{ readOnly: true }
			// 	},
			// }
		]
	]
}


export {
	config
}