import React from 'react';	
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp'; 



var ScenarioDefinitionNameModel = {
	objectKey: 'scenario_definition_name',
	widget: 'FormDropDown',
	text: 'Scenario Definition Name',
	data: []
};

var modelNameModel = {
	objectKey: 'model_name',
	widget: 'FormDropDown',
	text: 'Model Name',
	data: []
};

var modelDefinitionNameModel = {
	objectKey: 'model_definition_name',
	widget: 'FormDropDown',
	text: 'Model Definition Name',
	data: []
};

var csvWidgetProp = {
	disabled: true
}

var triggerDownload = function(){
	if(config.dispatcher){
		config.dispatcher();
	}
}

var csvObject = {
	objectKey: 'csv_file_name',
	widget: 'FormFileInput',
	text: (
		<React.Fragment>
			Download Sample CSV, Fill Data, and Upload CSV using Choose File Option <IconButton title="Download Sample CSV"  className="highlightdownload-icon" variant="contained" onClick={triggerDownload} >
				<GetAppIcon/>
			</IconButton>
		</React.Fragment>
	),
	widgetProps: {
		  InputProps: csvWidgetProp
	}
}


var config = {
	modelNameObj: modelNameModel,
	modelDefinitionNameObj: modelDefinitionNameModel,
	scenarioDefinitionNameObj: ScenarioDefinitionNameModel,
	csvWidgetProp :csvWidgetProp ,
	csvObject:csvObject,
	config: [
		[
			modelNameModel,
			modelDefinitionNameModel,
			ScenarioDefinitionNameModel
			,
            {
				objectKey: 'ConfigurationName',
				widget: 'FormTextInput',
				text: 'Configuration Name',
			},
			{
				objectKey: 'reporting_time',
				widget: 'FormDateTimeInput',
				text: 'Reporting Date',
			},			
			,
			csvObject
            
		],
		[
			// {
			// 	objectKey: 'send_for_approval',
			// 	widget: 'FormSwitch',
			// 	text: 'Send for Approval',
			// 	tooltip: 'Send for Approval will change approval status to Pending for approval',
			// }			
			
		]
	]
}


export {
	config
}