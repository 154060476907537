import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

function ScenarioWeightDataGrid(props) {
	
	const [rowData , setData] = React.useState(props.rows);
	var variableMap = {};
	
	React.useEffect (function(){
		setData(props.rows);
		variableMap = {};
		(rowData || []).forEach(function(_r){
			variableMap[_r.id] = _r;
		});
	},[props.rows]);
	
	
	(rowData || []).forEach(function(_r){
		variableMap[_r.id] = _r;
	});
	
	var columns=[
		{ field: 'scenario_name', headerName: 'Scenario Name',  flex: 1},
		{ field: 'scenario_weight_value', headerName: 'Weight Value', type: 'number', flex: 1,
			editable: !props.preventEdit
		},
	];
	
	
	const handleEditCellChangeCommitted = React.useCallback(
		({ id, field, props }) => {
			variableMap[id][field] = props.value;
		},
		[rowData, variableMap],
	  );
	
	
	
	return (
		<DataGrid  className={props.className} rows={rowData} columns={columns} 
			onEditCellChangeCommitted={handleEditCellChangeCommitted} />
	);
}

export default ScenarioWeightDataGrid;