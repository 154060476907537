var mainUrl=window.location.protocol+'//'+window.location.hostname;
//var mainUrl=window.location.protocol+'//'+window.location.hostname+':8000';
//var mainUrl='http://hdfc.example:8000';

var config = {
	login: mainUrl+'/rest-auth/login/',
	allMasterRecords: mainUrl+'/getMasterRecords/all/',
	getMasterRecords: mainUrl+'/getMasterRecord/',
	createMasterRecord: mainUrl+'/createMasterEntry/',
	deleteMasterRecord: mainUrl+'/deleteMasterRecord/',
	approveListRecord: mainUrl+'/getMasterRecords/allaction/',
	approveMasterRecord: mainUrl+'/updateMasterRecordApproval/',
	logout: mainUrl+'/logout/',
	//Model Definition
	approvedModels: mainUrl+'/getMasterRecords/approved/',
	approvedDefinitions: mainUrl+'/getDefinitionRecords/approved/',
	allModelDefinitionRecords: mainUrl+'/getDefinitionRecords/all/',
	getModelDefinitionRecord:mainUrl+'/getDefinitionRecord/',
	createModelDefinitionRecord: mainUrl+'/createDefinitionEntry/',
	deleteModelDefinitionRecord: mainUrl+'/deleteDefinitionRecord/',
	approveListModelDefinitionRecord: mainUrl+'/getDefinitionRecords/allaction/',
	approveModelDefinitionRecord: mainUrl+'/updateDefinitionApproval/',
	deleteModelDefinitionRecord:mainUrl+'/deleteDefinitionRecord/',
	approveListModelDefinitionRecord:mainUrl+'/getDefinitionRecords/allaction/',
	approveModelDefinitionRecord:mainUrl+'/updateDefinitionApproval/',
	// Audit Trail
	allAuditTrailRecords: mainUrl+'/getAuditTrails/all/',
	//Scenario Definition
	allScenarioDefinitionRecords: mainUrl+'/getScenarioDefinitionRecords/all/',
	getScenarioDefinitionRecord: mainUrl+'/getScenarioDefinitionRecord/',
	createScenarioDefinitionRecord: mainUrl+'/createScenarioDefinitionEntry/',
	deleteSceanrioDefinitionRecord: mainUrl+'/deleteScenarioRecord/',
	approveListScenarioDefinitionRecord: mainUrl+'/getScenarioDefinitionRecords/allaction/',
	approveScenarioDefinitionRecord: mainUrl+'/updateScenarioDefinitionApproval/',
	approvedScenarios: mainUrl+'/getScenarioDefinitionRecords/approved/',
	approveListScenarioDefinitionRecord:mainUrl+'/getScenarioDefinitionRecords/allaction/',
	approveScenarioDefinitionRecord:mainUrl+'/updateScenarioDefinitionApproval/',
	approvedScenarios:mainUrl+'/getScenarioDefinitionRecords/approved/',
	// ECL Computation
	allCongigurationRecords: mainUrl+'/getECLComputationRecords/all/',
	getConfigurationRecord: mainUrl+'/getECLComputationRecord/',
	createConfigurationRecord: mainUrl+'/uploadCSVforScenario/',
	downloadComputation: mainUrl+'/geteclconfigurationReportECLAggregate/',
	// Reporting
	modelWiseReporting: mainUrl+'/getmodelwisereport/',
	//userprofile
	getuserinfo: mainUrl+'/getUserInfo/',
	getuserinfo:mainUrl+'/getUserInfo/',
	getCSV:  mainUrl+'/getsamplecsv/',
	//dashboard
	getallReports: mainUrl+'/geteclallReportECLAggregate/',
	adminURL: mainUrl+ '/tenant-admin/'
}

export default config;
