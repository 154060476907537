import React from 'react';
import ModelMasterCreate from './Routes/ModelMasterCreate.js';
import ModelMasterList from './Routes/ModelMasterList.js';
import ModelMasterEdit from './Routes/ModelMasterEdit.js';
import ModelMasterApprove from './Routes/ModelMasterApprove.js';
import ModelMasterApproveList from './Routes/ModelMasterApproveList.js';
import ModelMasterView from './Routes/ModelMasterView.js';
import { Route} from 'react-router-dom';	
import APIConfig from '../../APIConfig.js'


function MainModelMasterRouter() {
	
	var listFetchingData = [
		{
			url: APIConfig.allMasterRecords,
			method: 'get',
			key: 'data'
		}
	];
	var approveListFetchingData = [
		{
			url: APIConfig.approveListRecord,
			method: 'get',
			key: 'data'
		}
	];
	var editFetchingData = [
		{
			url: APIConfig.getMasterRecords,
			method: 'get',
			key: 'data',
			preprocessor: function(_data){
				var currentModel = window.location.pathname.split('/').splice(-1);
				_data.url = _data.url+currentModel+'/';
				return _data;
			}
		}
	];
	
	var createDispatchers = {
		create: {
			method: 'post',
			url:  APIConfig.createMasterRecord,
		}
	}
	
	var editDispatchers = {
		edit: {
			method: 'put',
			url:  APIConfig.createMasterRecord,
		}
	}
	
	var listDispatchers = {
		delete: {
			method: 'delete',
			url:  APIConfig.deleteMasterRecord,
			preprocessor: function(_obj){
				_obj.url  = _obj.url  +_obj.row.model_name+'/';
				return _obj;
			}
		}
	}
	
	var approveDispatchers={
		approve: {
			method: 'put',
			url: APIConfig.approveMasterRecord
		}
		
	}
	
	return (
		<React.Fragment>
			<Route path="/model-master/edit/:id">
				<ModelMasterEdit fetchingData={editFetchingData} dispatcher={editDispatchers}/>
			</Route>
			<Route path="/model-master/view/:id">
				<ModelMasterView fetchingData={editFetchingData}/>
			</Route>
			<Route path="/model-master/list">
				<ModelMasterList fetchingData={listFetchingData} dispatcher={listDispatchers}/>
			</Route>
			<Route path="/model-master/create">
				<ModelMasterCreate dispatcher={createDispatchers}/>
			</Route>
			<Route path="/model-master/approve-list">
				<ModelMasterApproveList fetchingData={approveListFetchingData} dispatcher={listDispatchers}/>
			</Route>
			<Route path="/model-master/approve/:id">
				<ModelMasterApprove fetchingData={editFetchingData} dispatcher={approveDispatchers}/>
			</Route>
		</React.Fragment>
	);
}

export {
	MainModelMasterRouter
}
