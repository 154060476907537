import Switch from '@material-ui/core/Switch';
import React from 'react';

function FormSwitch(props) {
	
	const [value, setValue] = React.useState(props.defaultValue || false);

	React.useEffect(() => {
	   setValue(props.defaultValue || false);
	}, [props.defaultValue]);

	const handleChange = function(event){
		var newValue = !value;
		setValue(newValue);
		if(props.onUpdateValue){
			props.onUpdateValue(newValue, props.objectKey);
		}
	}

	return (
		<Switch
			id={(props.id || props.objectKey)}
			checked = {value}
			onChange = {handleChange}
			{...props.widgetProps}
		></Switch>
	);
}

export {
	FormSwitch
}