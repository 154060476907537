import React from 'react';
import { Route} from 'react-router-dom';	
import APIConfig from '../../APIConfig.js'
import DashboardView from './Routes/DashboardView.js';


function MainDashboardRouter() {
	
	var editFetchingData = [
		{
			url: APIConfig.getallReports,
			method: 'get',
			key: 'data',
			// preprocessor: function(_data){
			// 	var currentModel = window.location.pathname.split('/').splice(-1);
			// 	_data.url = _data.url+currentModel;
			// 	return _data;
			// }
		}
	];
	// var createDispatchers = {
	// 	fetchReport: {
	// 		url:  APIConfig.getallReports,
	// 		// preprocessor: function(_obj, data){
	// 		// 	_obj.url = _obj.url + _obj.model_name;
	// 		// 	return _obj;
	// 		// }
	// 	}
	// }

	return (
		<React.Fragment>
			<Route path="/">
				{/* <DashboardView dispatcher={createDispatchers} /> */}
				<DashboardView  fetchingData={editFetchingData}/>
			</Route>
		</React.Fragment>
	);
}

export {
	MainDashboardRouter
}
