import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import PopupState, { bindTrigger } from 'material-ui-popup-state';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { dataUIWrapper } from './dataUIWrapper.js';
import Button from '@material-ui/core/Button';
import { Person } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { performLogout } from '../../data/globalData/slices/userSlice';
import { useReactiveVar } from '@apollo/client';


function ApplicationHeader(props) {

	const [userName, setUserName] = React.useState('');
	
	React.useEffect(function(e){
		if(props.user){
			if(props.user.message){
				var name = (props.user.message.first_name || '')+' '+(props.user.message.last_name || '');
				setUserName(name);
			}
		}
	},[props.user])
	
	const StyledMenu = withStyles({
		paper: {
		  border: '1px solid #f0f3f5',
		},
	  })((props) => (
		<Menu
		  elevation={0}
		  getContentAnchorEl={null}
		  anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		  }}
		  transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		  }}
		  {...props}
		/>
	  ));
	
	  const StyledMenuItem = withStyles((theme) => ({
		root: {
		  '&:focus': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
			  color: theme.palette.common.white,
			},
		  },
		},
	  }))(MenuItem);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = React.useCallback(() => {
		setAnchorEl(null);
	},[setAnchorEl]);

	return (
		<AppBar className="app-header-main-section">
			<div className="ecl-flex-column">
				  <Typography variant="h6" className="app-header-title ">
				 &nbsp;
				  </Typography>
				  <div className="ecl-flex-spacer">
				  </div>
				   <PopupState variant="popover" popupId="demo-popup-popover">
				  {
					  (popupState) => (<React.Fragment>
				  <span  {...bindTrigger(popupState)}>
					  <Typography variant="h6" className="app-header-title ecl-user-title" >
						<Button aria-controls="simple-menu" className="ecl-header-user-space" aria-haspopup="true" onClick={handleClick}>
							Welcome, {userName}
							<AccountCircleIcon className="ecl-user-icon"/>
						</Button>
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
						<Link to={'/userprofile/'}>
						<StyledMenuItem>
						<ListItemIcon>
							<Person fontSize="small" />
						</ListItemIcon>
						<ListItemText primary="Profile" />
						</StyledMenuItem>
						</Link>
						<StyledMenuItem onClick={performLogout}>
						<ListItemIcon>
							<ExitToAppIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText primary="Logout" />
						</StyledMenuItem>
					</StyledMenu>
					  </Typography>
					</span>
					  </React.Fragment>)
				  }
					</PopupState>
			</div>	
		</AppBar>
	);
}

export default dataUIWrapper(ApplicationHeader, true);

