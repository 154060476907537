import { makeVar } from '@apollo/client';
import { createSlice } from '@reduxjs/toolkit';
import CookieHandler from '../../../Widgets/Global/CookieHandler.js';

var key = CookieHandler.get('key');

/* New Implementation */

const loginStatus = makeVar({
	key: key,
	status: (key?'logged':null),
});

const performLogin = function(key){
	CookieHandler.set('key', key);
	loginStatus({
		key: key,
		status: 'logged'
	});
}


const performLogout = function(key){
	CookieHandler.resetCookie();
			
	setTimeout(function(){
		window.location.reload()
	},2000);
	
	loginStatus({
		key: null,
		status: 'loggedOut'
	});
}



/****** End Implementation ******/


export {
	loginStatus,
	performLogin,
	performLogout
}
