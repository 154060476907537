import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as express from "express";
// import * as path from "path";
import App from './App';
import reportWebVitals from './reportWebVitals';

// const path = require('path');
// const express = require('express');
// const express = require('express');
// const app = express();

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);


/*App.use(express.static(__dirname)); //here is important thing - no static directory, because all static :)

App.get("/*", function(req, res) {

  res.sendFile(path.join(__dirname, "index.html"));

});*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
