import React from 'react';
import {Loader} from './Loader.js'
import CookieHandler from './CookieHandler.js'
import {notificationObj} from './NotificationHandler.js';
import { loginStatus, performLogout } from '../../data/globalData/slices/userSlice';
import { useReactiveVar } from '@apollo/client';

const axios = require('axios');
const Promise = require('promise')


console.log(CookieHandler);

function dataUIWrapper(MainComponent, skipWrapping) {
	
	return function(props){
		
		const userLoginStatus = useReactiveVar(loginStatus);
		
		const [dataObject, setDataObject] = React.useState({});
		const [dataLoading, setDataLoading] = React.useState(true);
		const [firstFetched, setFirstFetched] = React.useState(false);
		
		const _key = React.useMemo(function(){
			var cookieKey = CookieHandler.get('key');
			if(cookieKey !== userLoginStatus.key){
				performLogout();
				return;
			}
			else return cookieKey;
		},[userLoginStatus, CookieHandler.get('key')]);
		
		
		const handleErrorResponse = function(e){
			setDataLoading(false);
			if(e.response && e.response.status === 400){
				performLogout();
			}if(e.response && e.response.data && e.response.data.message){
				notificationObj.showNotification('error',e.response.data.message);
			}else{
				notificationObj.showNotification('error','Something went wrong, operation may not be successful');
			}
		}
		
		
		const refetech = function(){
			setDataLoading(true);
			setFirstFetched(true);
			var fetchingData = (props.fetchingData || []);
			
			var key = _key;
			if(!key){
				return;
			}
			
			var promiseArray = fetchingData.map(function(_f){
				if(_f.preprocessor){
					_f = _f.preprocessor({
						..._f
					});
				}
				var obj = {
					..._f,
					headers: {
						... _f.headers,
						Authorization: 'Bearer '+ key
					}
				};
				
				return axios(obj);
				
			});
		
		
			Promise.all(promiseArray)
				.then(function (results) {
					
					var _dataObj = {};
					fetchingData.forEach(function(_data, idx){
						
						if(fetchingData[idx].postProcessor){
							fetchingData[idx].postProcessor(_dataObj, results[idx].data);
						}else{
							_dataObj[_data.key] = results[idx].data;
							
						}
						
					});
					
					
					setDataObject(_dataObj);
					setDataLoading(false);
					
				}).catch(handleErrorResponse);
		}
		
		if(!firstFetched){
			refetech();	
		}
		
		
		
		const dispatchRequest = function(_dispatcher, data){
			
			
			var key = _key;
			if(!key){
				return;
			}
			
			var dispatcher = (props.dispatcher || {})[_dispatcher];
			if(dispatcher){
				setDataLoading(true);
				var _data = data || {};
				var obj = {
					... _data, 
					... dispatcher,
					headers: {
						... _data.headers,
						Authorization: 'Bearer '+ key
					}
				}
				
				if(dispatcher.preprocessor){
					obj = dispatcher.preprocessor({
						...obj
					});
				}
				var request;
				
				if(dispatcher.method === 'delete'){
					request = axios.delete(obj.url, obj);
				}else{
					request = axios(obj);
				}
				request.then(function(e){
					if(e.data && e.data.message){
						notificationObj.showNotification('success',e.data.message);
					}
					setDataLoading(false);					
				}).catch(handleErrorResponse);
				
				return request;
			}
			
			
		}
		
		if(skipWrapping){
			return ( <MainComponent {...dataObject}  dispatchRequest = {dispatchRequest} refetch={refetech}/> );
		}
		
		return (
			<div className={props.skipLoading?"":"full-container wait-wrapper"}>
				{(dataLoading &&  <Loader/>)}
				<MainComponent {...dataObject}  dispatchRequest = {dispatchRequest} refetch={refetech}/>
			</div>
		)
	
	}
}

export {
	dataUIWrapper
}
