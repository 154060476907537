var config = {
	config: [
		[
			{
				objectKey: 'model_definition_name',
				widget: 'FormTextInput',
				text: 'Model Definition Name',
				widgetProps: {
					  InputProps:{
						readOnly: true,
					  }
				}
			},
			{
				objectKey: 'scenario_definition_name',
				widget: 'FormTextInput',
				text: 'Scenario Definition Name',
				widgetProps: {
					  InputProps:{
						readOnly: true,
					  }
				}
			},
			{
				objectKey: 'no_of_scenarios',
				widget: 'FormTextInput',
				text: 'No Of Scenarios',
				widgetProps: {
					  InputProps:{
						readOnly: true,
					  }
				}
			},
			{
				objectKey: 'no_of_years',
				widget: 'FormTextInput',
				text: 'Years Per Scenarios',
				widgetProps: {
					  InputProps:{
						readOnly: true,
					  }
				}
			},	
			{
				widget: 'Spacer',
			},
			{
				objectKey: 'lgd_percent',
				widget: 'FormTextInput',
				text: 'LGD %',
				widgetProps: {
					  InputProps:{
						readOnly: true,
					  }
				}
			},
			{
				objectKey: 'current_pd_rate',
				widget: 'FormTextInput',
				text: 'Current PD Rate',
				widgetProps: {
					  InputProps:{
						readOnly: true,
					  }
				}
			},
			{
				objectKey: 'pd_transition_rate',
				widget: 'FormTextInput',
				text: 'PD Transition Rate %',
				widgetProps: {
					  InputProps:{
						readOnly: true,
					  }
				}
			},
			{
				widget: 'Spacer',
			},					
			{
				objectKey: 'created_user',
				widget: 'FormTextInput',
				text: 'Created User',
				widgetProps: {
					InputProps:{ readOnly: true }
				}
			},
			{
				objectKey: 'created_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Created Time',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				objectKey: 'created_user_ip_address',
				widget: 'FormTextInput',
				text: 'IP Address',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
		],
		[
			
			{
				objectKey: 'updated_user',
				widget: 'FormTextInput',
				text: 'Updated User',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				objectKey: 'updated_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Updated Time',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				widget: 'Spacer',
			},
			{
				objectKey: 'approval_status',
				widget: 'FormTextInput',
				text: 'Approval Status',
				
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			
			{
				objectKey: 'approved_user',
				widget: 'FormTextInput',
				text: 'Approved User',
				
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				objectKey: 'approved_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Approved Time',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			},
			{
				objectKey: 'approved_user_comment',
				widget: 'FormMultilineInput',
				text: 'Approved Comments',
				widgetProps: {
					InputProps:{ readOnly: true }
				},
			}
		]
	]
}


export {
	config
}