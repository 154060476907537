import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import {
  Chart,
  PieSeries,
  Legend,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation } from '@devexpress/dx-react-chart';
import { EventTracker } from '@devexpress/dx-react-chart';
import { DataGrid } from '@material-ui/data-grid';

const columns=[
		// { field: 'reporting_time', headerName: 'Reporting Time',  width: 250 },
		{ field: 'configuration_name', headerName: 'Configuration Name',  width: 250 },	
		{ field: 'scenario_definition_name', headerName: 'Scenerio Definition',  width: 250 },
		{ field: 'model_definition_name', headerName: 'Model Definition',  width: 250 },
		{ field: 'ecl_twelve_month_amount', headerName: '12-Month ECL',  width: 250 },
		{ field: 'lifetime_ecl_amount', headerName: 'Lifetime ECL',  width: 250 },
	];

function ModelChartReport(props) {
	
	const [aggregateData, setAggregateData] = React.useState([]);
	const [reportsData, setreportsData] = React.useState([]);
	
	React.useEffect(function(){
		var ecl_twelve_month_amount = 0,
			lifetime_ecl_amount = 0,
			_aggr, _repr=[];
		
		if(props.modelsData){
			props.modelsData.forEach(function(_model, idx){
					ecl_twelve_month_amount  = ecl_twelve_month_amount  + Number(_model.ecl_twelve_month_amount);
					lifetime_ecl_amount  = lifetime_ecl_amount  + Number(_model.lifetime_ecl_amount);
					_repr.push(
							{
								id: idx,
								title: ' ', 
								configuration_name: _model.configuration_name,
								scenario_definition_name: _model.scenario_definition_name,
								model_definition_name: _model.model_definition_name,
								ecl_twelve_month_amount:  Number(_model.ecl_twelve_month_amount),
								lifetime_ecl_amount:  Number(_model.lifetime_ecl_amount)
							}
					);
			
			});
			
			_aggr = [
				{title: '12-Month ECL', value: ecl_twelve_month_amount},
				{title: 'Lifetime ECL', value: lifetime_ecl_amount}
			] 
			
			//debugger;
			setAggregateData(_aggr);
			setreportsData(_repr);
		}
		
		
	},[props.modelsData]);
	
	const getBarChartReports = function(){
		var reports = [];
		
		reportsData.forEach(function(_obj, idx){
			debugger;
			_obj.ecl_twelve_month_amount = Number(_obj.ecl_twelve_month_amount);
			_obj.lifetime_ecl_amount = Number(_obj.lifetime_ecl_amount);
			
			reports.push(
				(
				<Card key={idx} idx={idx} className="main-ui-card inline-card">
					<div className="report-sub-title">
						Configuration Name
					</div>
					<Typography variant="h5" className="scenerio-def-title report-sub-value">
						{_obj.configuration_name || ''}
					</Typography>					
					<div className="report-sub-title">
						Scenario Definition
					</div>
					<Typography variant="h5" className="scenerio-def-title report-sub-value">
						{_obj.scenario_definition_name || ''}
					</Typography>
					<div className="report-sub-title">
						Model Definition
					</div>
					<Typography variant="h6" className="model-def-title report-sub-value">
						{_obj.model_definition_name || ''}
					</Typography>
				
					<Chart
						data={[_obj]}
						height={400}
						width={400}
					>
						<ArgumentAxis />
						<ValueAxis />
						<BarSeries
							name="12-Month ECL"
							valueField="ecl_twelve_month_amount"
							argumentField="title"
						  />
						 <BarSeries
							name="Lifetime ECL"
							valueField="lifetime_ecl_amount"
							argumentField="title"
						  />
						<Legend  position="bottom"/>
						<Stack />
						<Animation />
						<EventTracker />
						<Tooltip />
						
					</Chart>
				</Card>
				)		
			)
			
		});
		
		return reports;
		
	}
		
	
	
	return (
		<React.Fragment>
			<div className="card-flex-column">
				{/* <Card className="main-ui-card">
					<Typography variant="h5" className="app-header-title  ecl-header-title">
						{props.modelName || ''} (Aggregate Data)
					</Typography>
					<Chart
						className={'main-model-chart'}
					  data={aggregateData} 
					>
						  <PieSeries
							valueField="value"
							argumentField="title"
						  />
						   <Legend  position="bottom"/>
						  <Animation />
						  <EventTracker />
						<Tooltip />
					</Chart>
				</Card> */}
				<Card className="main-ui-card report-grid-card">
					<DataGrid rows={reportsData} columns={columns}  />
				</Card>
			</div>
			<div className="inline-chart-container">
			<Typography variant="h5" className="app-header-title  ecl-header-title">
				{props.modelName || ''} (Config Wise Data)
			</Typography>
			{  getBarChartReports() }
			</div>
		</React.Fragment>
	);
}

export default ModelChartReport;