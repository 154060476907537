import React from 'react';
import ModelDefinitionCreate from './Routes/ModelDefinitionCreate.js';
import ModelDefinitionList from './Routes/ModelDefinitionList.js';
import ModelDefinitionEdit from './Routes/ModelDefinitionEdit.js';
import ModelDefinitionApprove from './Routes/ModelDefinitionApprove.js';
import ModelDefinitionApproveList from './Routes/ModelDefinitionApproveList.js';
import ModelDefinitionView from './Routes/ModelDefinitionView.js';
import { Route} from 'react-router-dom';	
import APIConfig from '../../APIConfig.js'


function MainModelDefinitionRouter() {
	
	var listFetchingData = [
		{
			url: APIConfig.allModelDefinitionRecords,
			method: 'get',
			key: 'data'
		}
	];
	var approveListFetchingData = [
		{
			url: APIConfig.approveListModelDefinitionRecord,
			method: 'get',
			key: 'data'
		}
	];
	
	var editFetchingData = [
		{
			url: APIConfig.getModelDefinitionRecord,
			method: 'get',
			postProcessor: function(dataObj, result){
				if(result){
					dataObj.formData = result[0][0];
					dataObj.rowData = result[1] || [];
				}
			},
			preprocessor: function(_data){
				var currentModel = window.location.pathname.split('/').splice(-1);
				_data.url = _data.url+currentModel+'/';
				return _data;
			}
		}
	];
	
	var createDispatchers = {
		create: {
			method: 'post',
			url:  APIConfig.createModelDefinitionRecord,
		},
		fetchVariables: {
			url:  APIConfig.getMasterRecords,
			preprocessor: function(_obj, data){
				_obj.url = _obj.url + _obj.model_name+'/';
				return _obj;
			}
		}
	}
	
	var editDispatchers = {
		edit: {
			method: 'put',
			url:  APIConfig.createModelDefinitionRecord,
		}
	}
	
	var listDispatchers = {
		delete: {
			method: 'delete',
			url:  APIConfig.deleteModelDefinitionRecord,
			preprocessor: function(_obj){
				_obj.url  = _obj.url  +_obj.row.model_definition_name+'/';
				return _obj;
			}
		}
	}
	
	var approveDispatchers={
		approve: {
			method: 'put',
			url: APIConfig.approveModelDefinitionRecord
		}
		
	}
	
	
	var createFetcher = [
		{
			url: APIConfig.approvedModels,
			key: 'models'
		}
	]
	
	
	return (
		<React.Fragment>
			<Route path="/model-definition/edit/:id">
				<ModelDefinitionEdit fetchingData={editFetchingData} dispatcher={editDispatchers}/>
			</Route>
			<Route path="/model-definition/view/:id">
				<ModelDefinitionView fetchingData={editFetchingData}/>
			</Route>
			<Route path="/model-definition/list">
				<ModelDefinitionList fetchingData={listFetchingData} dispatcher={listDispatchers}/>
			</Route>
			<Route path="/model-definition/create">
				<ModelDefinitionCreate fetchingData={createFetcher} dispatcher={createDispatchers}/>
			</Route>
			<Route path="/model-definition/approve-list">
				<ModelDefinitionApproveList fetchingData={approveListFetchingData} dispatcher={listDispatchers}/>
			</Route>
			<Route path="/model-definition/approve/:id">
				<ModelDefinitionApprove fetchingData={editFetchingData} dispatcher={approveDispatchers}/>
			</Route>
		</React.Fragment>
	);
}

export {
	MainModelDefinitionRouter
}
