var variableMasterData = [
    {
        "id": "V1",
        "variable_id": "V1",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V2",
        "variable_id": "V2",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V3",
        "variable_id": "V3",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V4",
        "variable_id": "V4",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V5",
        "variable_id": "V5",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V6",
        "variable_id": "V6",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V7",
        "variable_id": "V7",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V8",
        "variable_id": "V8",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V9",
        "variable_id": "V9",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V10",
        "variable_id": "V10",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V11",
        "variable_id": "V11",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V12",
        "variable_id": "V12",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V13",
        "variable_id": "V13",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V14",
        "variable_id": "V14",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V15",
        "variable_id": "V15",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V16",
        "variable_id": "V16",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V17",
        "variable_id": "V17",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V18",
        "variable_id": "V18",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V19",
        "variable_id": "V19",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V20",
        "variable_id": "V20",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V21",
        "variable_id": "V21",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V22",
        "variable_id": "V22",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V23",
        "variable_id": "V23",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V24",
        "variable_id": "V24",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V25",
        "variable_id": "V25",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V26",
        "variable_id": "V26",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V27",
        "variable_id": "V27",
        "variable_name": "",
        "variable_type": ""
    },
    {
        "id": "V28",
        "variable_id": "V28",
        "variable_name": "",
        "variable_type": ""
    }
]


export {
	variableMasterData
}