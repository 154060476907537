import React from 'react';
import {config} from '../config/ScenarioDefinitionEdit.js';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import {notificationObj} from '../../Global/NotificationHandler.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  { Redirect } from 'react-router-dom';
import ScenarioWeightDataGrid from '../../Global/ScenarioWeightDataGrid.js';
import VariableCurrentMVDataGrid from '../../Global/VariableCurrentMVDataGrid.js';
import ScenarioYearwiseDataGrid from '../../Global/ScenarioYearwiseDataGrid.js';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function ScenarioDefinitionEdit(props) {
	
	const [apiConf, setApiRef] = React.useState({});
	const [created, setCreated] = React.useState(false);
	const [defaultData, setDefaultData] = React.useState({no_of_scenarios: 0, no_of_years:0});
	const [selectedScenerio, setSelectedScenerio] = React.useState(0);
	const [scenerioYearWiseData, setScenerioYearwiseData] = React.useState([]);	
	const [scenerioWeights, setScenerioWeights] = React.useState([	]);	
	const [numberOfYears, setNoOfYears] = React.useState(0);
	const [numberOfScenerios, setNumberOfScenerios] = React.useState(0);
	
	React.useEffect(function(){
		if(props.formData){
			
			setDefaultData(props.formData);
			setNoOfYears(props.formData.no_of_years || 0);
			setNumberOfScenerios(props.formData.no_of_scenarios || 0);
		}
	},[props.formData]);
	
	React.useEffect(function(){
		if(props.scenerioWeights){
			var _scenerioWeights = props.scenerioWeights.slice();
			
			for(var i = (_scenerioWeights.length+1); i<=5;++i){
				_scenerioWeights.push({ id:i, scenario_name: "Scenario "+i });
			}
			setScenerioWeights(_scenerioWeights);
		}
	},[props.scenerioWeights]);
	
	React.useEffect(function(){
		if(props.scenerioYearWiseData){
			setScenerioYearwiseData(props.scenerioYearWiseData);
		}
	},[props.scenerioYearWiseData]);
	
	
	
	const editClick = React.useCallback(function(){
		
		var formData = apiConf.getData(),
			isError = false;
		
		props.variableMVData.forEach(function(_row){
			if(!_row.variable_current_mv){
				isError = 'Enter scenerio MV';
			}
		});
		
		if(isError){
			notificationObj.showNotification('error',isError);
			return;
		}
		
		var total = 0;
		
		scenerioWeights.slice(0, numberOfScenerios).forEach(function(_row){
			if(isError){
				return false;
			}
			if(!_row.scenario_weight_value){
				isError = 'Enter scenerio weights';
			}
			total=total +Number(_row.scenario_weight_value)*1000;
		});
		
		if(isError){
			notificationObj.showNotification('error',isError);
			return;
		}
		
		
		if(total!==1000){
			notificationObj.showNotification('error','Sum of weight value should equal to 1.0');
			return;
		}
		
		
		var allscenarions = scenerioYearWiseData.slice(0, numberOfScenerios).map(function(_scList, idx){
			
			return {
				"scenario_name": "Scenario "+(idx+1),
				"variables": _scList.map(function(_sc){
					
					var years = {};
					for(var i =1; i <= numberOfYears; ++i){
						years[i] = _sc['year'+i] || 0;
					}
					return {
						"variable_name" : _sc.variable_name,
						"years": years
					}				
				})
			}
		});
		
		var obj= {
			... formData,
			is_active: true,
			Scenario_CurrentMVs: props.variableMVData,
			Scenario_Weights: scenerioWeights.slice(0, numberOfScenerios),
			allscenarions
		}
		
		props.dispatchRequest('edit', {data: obj} ).then(function(){
			setCreated(true); 
		});
		
	},[props, scenerioWeights, scenerioYearWiseData, numberOfYears, numberOfScenerios, apiConf]);
	
	const updateFormData = React.useCallback(function(key, value){
		if(key === 'no_of_years'){
			setNoOfYears(Number(value));
		}if(key === 'no_of_scenarios'){
			setNumberOfScenerios(Number(value));
		}
	},[]);
	
	const handleScenerioChange = React.useCallback(function(e, newValue){
		setSelectedScenerio(newValue);
	},[]);
	
	const ScenerioTabs = React.useMemo(function(){
		var _tabs = [];
		
		for(var i =0;i< numberOfScenerios; i++){
			_tabs.push(<Tab label={"Scenario "+(i+1)} key={i}/>);
		}
		
		return (<React.Fragment>
			<AppBar position="static" className="card-tabs">
				<Tabs value={selectedScenerio} onChange={handleScenerioChange} aria-label="simple tabs example">
					{_tabs}
				</Tabs>
			</AppBar>
			<div className="ecl-grid-container">
				<ScenarioYearwiseDataGrid  numberOfYears={numberOfYears} className="scenario-yearwise-grid variable-grid" rows={(scenerioYearWiseData[selectedScenerio] || [])} />
			</div>
		</React.Fragment>);
		
	},[numberOfScenerios, numberOfYears, selectedScenerio, scenerioYearWiseData])
	
	
	var _data = (props.data || []);
	
	if(created){
		return (<Redirect to='/scenario-definition/list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Edit Scenario Definition
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/scenario-definition/list/">
					Scenario Definition (All List)
					</Link>
					<Typography color="textPrimary">{_data[0] && (_data[0].model_name)}</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
				Scenario Definition
				</Typography>
				<FormBuilder {...config} data={defaultData} onUpdate = { updateFormData } apiConf={apiConf}/>
			</Card>
			
			<div className="card-flex-column">
				<Card className="main-ui-card">
					<Typography variant="h6" className="app-sub-title ecl-header-title">
						Variable Current MV Values
					</Typography>
					
					<div className="ecl-grid-container">
						 <VariableCurrentMVDataGrid  className="variable-current-mv-grid variable-grid" rows={(props.variableMVData || [])} />
					</div>
				</Card>

				<Card className="main-ui-card">
					<Typography variant="h6" className="app-sub-title ecl-header-title">
						Scenario Weights
					</Typography>
					
					<div className="ecl-grid-container">
						 <ScenarioWeightDataGrid  className="scenario-weight-grid variable-grid" rows={(scenerioWeights.slice(0, numberOfScenerios))} />
					</div>
				</Card>
			</div>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Scenario Year Wise
				</Typography>
				{ScenerioTabs}
			</Card>
			
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick={editClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ScenarioDefinitionEdit);