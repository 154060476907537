var modelDefinitionNameModel = {
	objectKey: 'model_definition_name',
	widget: 'FormDropDown',
	text: 'Model Definition Name',
	data: []
};

var config = {
	modelDefinitionNameObj: modelDefinitionNameModel,
	config: [
		[
			modelDefinitionNameModel,
			{
				objectKey: 'scenario_definition_name',
				widget: 'FormTextInput',
				text: 'Scenario Name',
			},
			{
				objectKey: 'no_of_scenarios',
				widget: 'FormDropDown',
				text: 'No Of Scenarios',
				data: [
					{ text: 1, value: 1 },{ text: 2, value: 2 },{ text: 3, value: 3 },{ text: 4, value: 4 },{ text: 5, value: 5 },
				]
			},
			{
				objectKey: 'no_of_years',
				widget: 'FormDropDown',
				text: 'Years Per Scenario',
				data: [
					{ text: 1, value: 1 },{ text: 2, value: 2 },{ text: 3, value: 3 },{ text: 4, value: 4 },{ text: 5, value: 5 },{ text: 6, value: 6 },{ text: 7, value: 7 },{ text: 8, value: 8 },{ text: 9, value: 9 },{ text: 10, value: 10 },
					{ text: 11, value: 11 },{ text: 12, value: 12 },{ text: 13, value: 13 },{ text: 14, value: 14 },{ text: 15, value: 15 },{ text: 16, value: 16 },{ text: 17, value: 17 },{ text: 18, value: 18 },{ text: 19, value: 19 },{ text: 20, value: 20 },
					{ text: 21, value: 21 },{ text: 22, value: 22 },{ text: 23, value: 23 },{ text: 24, value: 24 },{ text: 25, value: 25 },{ text: 26, value: 26 },{ text: 27, value: 27 },{ text: 28, value: 28 },{ text: 29, value: 29 },{ text: 30, value: 30 },
				]
			},					
			{
				objectKey: 'send_for_approval',
				widget: 'FormSwitch',
				text: 'Send for Approval',
				tooltip: 'Send for Approval will change approval status to Pending for approval',
			}
		],
		[
			{
				objectKey: 'lgd_percent',
				widget: 'FormTextInput',
				text: 'LGD %',
				tooltip: 'Any numeric value, positive or negative',
				widgetProps: {
					type: "number",
					InputProps: {
						inputProps:{
							min: 0, max: 100
						}
					}
				}
			},			
			{
				objectKey: 'current_pd_rate',
				widget: 'FormTextInput',
				text: 'Current PD Rate',
				tooltip: 'Any numeric value, positive or negative',
				widgetProps: {
					type: "number",
					
				}
			},	
			{
				objectKey: 'pd_transition_rate',
				widget: 'FormTextInput',
				text: 'PD Transition Rate %',
				tooltip: 'Any numeric value, positive or negative',
				widgetProps: {
					type: "number"
				}
			}	
		]
		// ,
		// [
		// 	{	
		// 		widget: 'Spacer',
		// 	},
		// 	{
		// 		objectKey: 'send_for_approval',
		// 		widget: 'FormSwitch',
		// 		text: 'Send for Approval'
		// 	}
		// ]
	]
}


export {
	config
}