import React from 'react';
import {MainModelMasterRouter} from '../ModelMaster/MainRouter.js';
import { Redirect, Route} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { LoginController} from './LoginController.js';
import { LogoutController} from './LogoutController.js';
import { MainAuditTrailRouter } from '../AuditTrail/MainRouter.js';
import { MainScenarioDefinitionRouter } from '../ScenarioDefinition/MainRouter.js';
import { MainModelDefinitionRouter } from '../ModelDefinition/MainRouter.js';
import { MainComputationRouter } from '../Computation/MainRouter.js';
import { MainReportRouter } from '../Reports/MainRouter.js';
import dashboardimage from '../../dashboardimage.png'
import { MainUserProfileRouter } from '../UserProfile/MainRouter.js';
import { MainDashboardRouter } from '../Dashboard/MainRouter.js';
import { loginStatus } from '../../data/globalData/slices/userSlice';
import { useReactiveVar } from '@apollo/client';
import AdminView from '../Admin/admin.js'



function MainRouter() {
	
	const userLoginStatus = useReactiveVar(loginStatus);
	
	var LoginModel = React.useMemo(()=>{
		if(userLoginStatus.status === 'loggedOut'){
			if(window.location.pathname !== "/logout-user"){
				return <Redirect to='/logout-user' />
			}
		}if(!userLoginStatus.status){
			if(window.location.pathname !== "/login"){
				return <Redirect to='/login' />
			}
		}else if(window.location.pathname === "/login" || window.location.pathname === "/logout-user" ){
				return <Redirect to='/' />
		}
		
		return null;
	}, [userLoginStatus.status] )
	
	return (
		<React.Fragment>
			<Route exact path="/">
			<MainDashboardRouter/>
			</Route>
			<Route exact path="/login">
				<LoginController/>
			</Route>
			<Route exact path="/logout-user">
				<LogoutController/>
			</Route>
			<MainModelMasterRouter/>
			<MainModelDefinitionRouter/>
			<MainScenarioDefinitionRouter/>
			<MainAuditTrailRouter/>
			<MainComputationRouter/>
			<MainReportRouter/>
			<MainUserProfileRouter/>
			<Route path="/admin">
				<AdminView/>
			</Route>
			{LoginModel}
		</React.Fragment>
	);
}

export {
	MainRouter
}
