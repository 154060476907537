import React from 'react';
import {config} from '../config/ModelDefinitionCreate';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import VariableModelDefinitionDataGrid from '../../Global/VariableModelDefinitionDataGrid.js';
import {notificationObj} from '../../Global/NotificationHandler.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  { Redirect } from 'react-router-dom';


function ModelDefinitionCreate(props) {
	
	const [apiConf, setApiRef] = React.useState({});
	const [rowData, setRowData] = React.useState([]);
	const [created, setCreated] = React.useState(false);
	const [defaultData, setDefaultData] = React.useState({send_for_approval: false});
	
	
	
	React.useEffect(function(){
		if(props.models){
			config.modelNameObj.data = props.models.map(function(_model){
				return {
					text: _model.model_name,
					value: _model.model_name
				}
			});
		}
	},[props.models]);
	
	
	const updateRowData = React.useCallback(function(_data){
		
		if(_data){
			_data = _data.map(function(_d){
				if(!_d.variable_name){
					return null;
				}
				
				return {
					id: _d.id,
					"variable_id": _d.variable_id,
					"variable_name": _d.variable_name,
					"variable_type":  _d.variable_type,
					"variable_selected_value": _d.variable_selected_value || '',
					"variable_selected_yn":_d.variable_selected_yn || 'FALSE'
				}
			}).filter((x)=>x);
			setRowData(_data);
		}else{
			setRowData([]);
		}
		
	},[]);
	
	const updateForm = React.useCallback(function(key, value){
		if(key === 'model_name'){
			props.dispatchRequest('fetchVariables', {model_name: value} ).then(function(e){
				updateRowData(e.data[1]);
			});	
		}
	},[props, updateRowData]);
	
	const createClick =  React.useCallback(function(){
		
		var isError = false;
		
		var formData = apiConf.getData();
		
		if(!formData.model_name){
			notificationObj.showNotification('error','Enter model name');
			return;
		}
		
		if(!formData.model_definition_name){
			notificationObj.showNotification('error','Enter model definition');
			return;
		}
		
		if(!formData.model_type){
			notificationObj.showNotification('error','Enter model type');
			return;
		}
		
		var obj= {
			... formData,
			variables: rowData.map(function(_d){
				debugger;
				if(isError){
					return null;
				}
				
				if(_d.variable_selected_yn === "TRUE" && !_d.variable_selected_value){					
					isError = true;
					notificationObj.showNotification('error','Enter variable value for selected variables');
				}
			
				if(_d.variable_type === "categorical" && ( _d.variable_selected_value > 20 || _d.variable_selected_value < 0 )){
					isError = true;
					notificationObj.showNotification('error','Categorical value should be between 0 to 20');
				}
				
				return {
					id: _d.id,
					"variable_id": _d.variable_id,
					"variable_name": _d.variable_name,
					"variable_type":  _d.variable_type,
					"variable_selected_value": _d.variable_selected_yn === "TRUE"?_d.variable_selected_value:'',
					"variable_selected_yn":_d.variable_selected_yn || 'FALSE'
				}
				
				
			})
		}
		
		if(isError){
			return;
		}
		
		props.dispatchRequest('create', {data: obj} ).then(function(){
			setCreated(true); 
		});
		
	},[props, rowData, apiConf]);
	
	if(created){
		return (<Redirect to='/model-definition/list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Create Model Definition
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/model-definition/list/">
					Model Definition (All List)
					</Link>
					<Typography color="textPrimary">Create</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Definition
				</Typography>
				<FormBuilder {...config} apiConf={apiConf} onUpdate={updateForm}  data= { defaultData } />
			</Card>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Definition Variables
				</Typography>				
				<div className="ecl-grid-container">
					 <VariableModelDefinitionDataGrid  className="variable-grid" rows={rowData} />
				</div>
			</Card>
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick = {createClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ModelDefinitionCreate);