import React from 'react';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import Card from '@material-ui/core/Card';
import { Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {notificationObj} from '../../Global/NotificationHandler.js';
import preprocessors from '../../Global/FormBuilder/Preprocessors/preprocessors.js';

import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

function ScenarioDefinitionList(props) {
	
	
	const handleDelete = React.useCallback(function(_row){
		console.log(_row);
		
		notificationObj.showConfirmBox({
			description: 'Do you want to delete the scenario?',
			ok: function(){					
				props.dispatchRequest('delete', {
					row: _row
				}).then(function(){
					props.refetch();
				});
			}
		});
	},[props]);
	
	var columns =  React.useMemo(function(){
		return [
		  { field: 'scenario_definition_name', headerName: 'Scenario Name',   width: 350,
				renderCell : function(params){
				
					var canDelete = !(params.row.approval_status === 'Pending' || params.row.approval_status === 'Approved');
					var canEdit = !(params.row.approval_status === 'Approved' || params.row.approval_status === 'Pending');
					 
					 return (
						<div className="row-icon-container">
							<div className="row-label">
								{params.row.scenario_definition_name}
							</div>
							{canDelete && (<Link to="#">
								<IconButton title="Delete" variant="contained" onClick={()=>{handleDelete(params.row)}}>
									<DeleteIcon className="ecl-delete-icon"/>
								</IconButton>
							</Link>)}
							{ canEdit && (<Link title="Edit" to={'/scenario-definition/edit/'+params.row.scenario_definition_name}>
								<IconButton variant="contained">
									<CreateIcon className="ecl-edit-icon"/>
								</IconButton>
							</Link>) }
							<Link to={'/scenario-definition/view/'+params.row.scenario_definition_name} >
								<IconButton title="View" variant="contained">
									<VisibilityIcon  className="ecl-view-icon"/>
								</IconButton>
							</Link>
						</div>
					)
				}
		  
		  },
		  { field: 'model_definition_name', headerName: 'Model Definition Name', width: 210,},
		  { field: 'approval_status', headerName: 'Approval Status', width: 190,
				renderCell: function(params){
					return (<div className={"ecl-status-"+params.row.approval_status.toLowerCase()+" ecl-status-div"}>{params.row.approval_status}</div>);
				}
		  },  
		  { field: 'created_user', headerName: 'Created User', width: 190 },	  
		  { field: 'created_time', headerName: 'Created Time', width: 190, type: 'date',
				valueFormatter: (params) => {
					return preprocessors.date(params.value, ' ');
				} },	  	  
		  { field: 'approved_user', headerName: 'Approved User', width: 190 },	  
		  { field: 'approved_time', headerName: 'Approved Time', width: 190, type: 'date',
				valueFormatter: (params) => {
					return preprocessors.date(params.value, ' ');
				} }
		];
	},[props])
	
	
	return (
		<div className="right-section-contents">
			<div className="content-heading">
				<Typography variant="h4" className="app-header-title ecl-header-title">
					List Scenario Definition
					<Breadcrumbs aria-label="breadcrumb">
						<Typography color="textPrimary">
						Scenario Definition (All List)
						</Typography>
					</Breadcrumbs>
				</Typography>
				<Link to="/scenario-definition/create/" >
						<Button variant="contained" color="primary" className="heading-button" >
							Create Scenario					
						</Button>
				</Link>
			</div>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Scenario Definitions
				</Typography>
				<div className="ecl-list-grid-container">
					 <DataGrid rows={(props.data || [])} columns={columns} />
				</div>
			</Card>
		</div>
	);
}

export default dataUIWrapper(ScenarioDefinitionList);