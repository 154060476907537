import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Checkbox from '@material-ui/core/Checkbox';

function VariableModelDefinitionDataGrid(props) {
	
	const [rowData , setData] = React.useState(props.rows);
	var variableMap = {};
	
	React.useEffect (function(){
		setData(props.rows);
		variableMap = {};
		(rowData || []).forEach(function(_r){
			variableMap[_r.id] = _r;
		});
	},[props.rows]);
	
	
	(rowData || []).forEach(function(_r){
		variableMap[_r.id] = _r;
	});
	
	
	const handleChange = function(value, _data, key){
		variableMap[_data.row.id][key] = value;
		_data.row[key] = value;
	}
	
	var columns=[
		{ field: 'variable_id', headerName: 'Variable ID', width: 300},
		{ field: 'variable_name', headerName: 'Variable Name',  flex: 1},
		{ field: 'variable_type', headerName: 'Variable Type',  flex: 1 },
		{ field: 'variable_selected_yn', headerName: 'Selected Variable',  width: 200,
			cellClassName: 'ecl-checkbox-col',
			renderCell: function(params){
				return (
				<Checkbox
					defaultChecked
					color="primary"
					checked={params.row.variable_selected_yn === "TRUE"}
					onChange={(e)=>{ handleChange(params.row.variable_selected_yn === "TRUE"?"FALSE":"TRUE", params, 'variable_selected_yn') }}
					inputProps={{ 'aria-label': 'secondary checkbox' }}
				 />
				)
			}
		},
		{ field: 'variable_selected_value', headerName: 'Variable Value (Numeric - Positive/negative value, Categorical - MIN = 0, MAX = 20)', type: 'number', width: 500, editable: true, 
			
			valueFormatter: (params) => {
				return params.row.variable_selected_yn === "TRUE"?params.value:'';
			}
		},
	];
	
	
	const handleEditCellChangeCommitted = React.useCallback(
		({ id, field, props }) => {
			variableMap[id][field] = props.value;
		},
		[variableMap],
	  );
	
	
	
	return (
		<DataGrid  className={props.className} rows={rowData} columns={columns} 
			onEditCellChangeCommitted={handleEditCellChangeCommitted}
			isCellEditable={ (params)=>{
						return params.row.variable_selected_yn === "TRUE"
			}}
		/>
	);
}

export default VariableModelDefinitionDataGrid;