import React from 'react';

function Loader(MainComponent) {
	
		
		return (
			<div className="ecl-loader">
				<div className="lds-grid">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		)
	
}

export {
	Loader
}
