
var CookieHandler = {
	
	get: function(key){
		var cookies={};
		var docCookie = document.cookie || '';
		docCookie.split(' ').forEach(function(_value){
			var keys = _value.split('=');
			cookies[keys[0]] = (keys[1] || '');
		});
		
		return cookies[key];
	},
	set: function(key, value){
		document.cookie = key +'='+value;
	},
	resetCookie: function(){
		document.cookie='key=\'\'; expires=Thu, 01 Jan 1970 00:00:00; path=\'/\';';
	}
	
	
	
}


export default CookieHandler;
