import React from 'react';
import {config} from '../config/ModelDefinitionEdit.js';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import {notificationObj} from '../../Global/NotificationHandler.js';
import VariableModelDefinitionDataGrid from '../../Global/VariableModelDefinitionDataGrid.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  { Redirect } from 'react-router-dom';

function ModelDefinitionEdit(props) {
	
	
	const [created, setCreated] = React.useState(false);
	const [apiConf, setApiRef] = React.useState({});
	
	
	var editClick = React.useCallback(function(){
		
		var formData = apiConf.getData();
		var isError = false;
		
		if(!formData.model_type){
			notificationObj.showNotification('error','Model Type Cannot be blank');
			return;
		}
		
		var obj = {
			... formData,
			variables: (props.rowData || []).map(function(_r){
				
				if(isError){
					return;
				}
				
				if(_r.variable_selected_yn === "TRUE" && !_r.variable_selected_value){					
					isError = true;
					notificationObj.showNotification('error','Enter variable value for selected variables');
				}
			
				if(_r.variable_type === "categorical" && ( _r.variable_selected_value > 20 || _r.variable_selected_value < 0 )){					
					isError = true;
					notificationObj.showNotification('error','Categorical value should be between 0 to 20');
				}
				
				return {
					variable_id: _r.variable_id,
					variable_name: _r.variable_name,
					variable_type: _r.variable_type,
					variable_selected_value: _r.variable_selected_value,					
					variable_selected_yn: _r.variable_selected_yn					
				}
			})
		}
		
		if(isError){
			return;
		}
		
		props.dispatchRequest('edit', {data: obj} ).then(function(){
			notificationObj.showNotification('success',' Model definition edited successfully');
			setCreated(true);
		}
		);
		
	},[props]);
	
	if(created){
		return (<Redirect to='/model-definition/list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Edit Model Definition
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/model-definition/list/">
					Model Definition(All List)
					</Link>
					<Typography color="textPrimary">{props.formData && (props.formData.model_definition_name)}</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Definition
				</Typography>
				<FormBuilder {...config} data={props.formData} apiConf={apiConf} />
			</Card>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Definition Variables
				</Typography>
				
				<div className="ecl-grid-container">
					 <VariableModelDefinitionDataGrid  className="variable-grid" rows={props.rowData || []} />
				</div>
			</Card>
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick={editClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ModelDefinitionEdit);