import { jsPDF } from "jspdf";
import preprocessors from '../../Global/FormBuilder/Preprocessors/preprocessors.js';
var css = `
<style>
	#pdfDiv{
		padding: 2px;
		box-sizing: border-box;
	}
	#pdfDiv *{
		font-size: 5px;
	}
	
	.pdf-form-title{
		font-weight: bold;
		margin-top: 0.25em;
		font-size: 1.25em!important;
	}
	
	#pdfDiv table td {
		border-bottom: 0.01em solid;
		padding: 0.5em 1.5em;
		font-size: 0.9em;
	}

	#pdfDiv table {
		border-spacing: 0;
		margin-top: 1em;
		border: 0.01em solid;
	}

	#pdfDiv table th {
		font-size: 1em;
		font-weight: bolder;
		padding: 0.5em 2em;
		border-bottom: 0.01em solid black;
		background: #c9d2fb;
	}

</style>
`

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const downloadCSV = function(data, fileName){
	console.log(data);
	var _csvData = 'Reporting Time'+ preprocessors.date(data.reporttime, ' ') +'\n\n'+
	'Portfolio Level ECL Aggregates\n'+
	'Portfolio Value, 12-Month ECL, Lifetime ECL\n'+
	data.eclAggregate.portfolio_value+','+data.eclAggregate.ecl_twelve_month_amount+','+data.eclAggregate.lifetime_ecl_amount+'\n\n'+
	'ECL Details\n'+
	'Account ID 1,Account ID 2,12-Month ECL,Lifetime ECL,Outstanding Amount\n'+
	data.eclPortfolio.map(function(_pf){
		return _pf.account_id_1+','+_pf.account_id_2+','+_pf.ecl_twelve_month_amount+','+_pf.lifetime_ecl_amount+','+_pf.outstanding_amount
	}).join('\n');
	
	download(fileName+'.csv', _csvData );
}

const createHTML = function(data){
	
	return css+
	'<h3>Reporting Time</h3>'+ preprocessors.date(data.reporttime, ' ') +'\n\n'+
	'<h3>Portfolio Level ECL Aggregates</h3>'+
	'<table class="pdf-aggregate"><thead><tr><th>Portfolio Value</th><th>12-Month ECL</th><th>Lifetime ECL</th></tr></thead><tbody>'+
	'<tr><td>'+data.eclAggregate.portfolio_value+'</td><td>'+data.eclAggregate.ecl_twelve_month_amount+'</td><td>'+data.eclAggregate.lifetime_ecl_amount+'</td></tr></table><tbody><br/>'+
	'<h3>ECL Details</h3>'+
	'<table><thead><tr><th>Account ID 1</th><th>Account ID 2</th><th>12-Month ECL</th><th>Lifetime ECL</th><th>Outstanding Amount</th></tr></thead><tbody>'+
	data.eclPortfolio.map(function(_pf){
		return '<tr><td>'+_pf.account_id_1+'</td><td>'+_pf.account_id_2+'</td><td>'+_pf.ecl_twelve_month_amount+'</td><td>'+_pf.lifetime_ecl_amount+'</td><td>'+_pf.outstanding_amount+'</td></tr>'
	}).join('')+'</table><tbody>';
	
}


const convertToPDF = function(json, fileName){
	
	const doc = new jsPDF({
		 orientation: 'l',
		 unit: 'mm',
		 format: 'a4',
		 putOnlyUsedFonts:true
	});
	
	var elem  = document.createElement('div');
	
	downloadCSV(json, fileName);
	
	//elem.innerHTML = createHTML(json);
	
	//window.pdfDiv.appendChild(elem);
	//doc.html(window.pdfDiv, {
	//   callback: function (doc) {
	//	 doc.save(fileName+".pdf");
	//	 window.pdfDiv.innerHTML = '';
	//   }
	//});
		
}


export default convertToPDF;