import React from 'react';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import Card from '@material-ui/core/Card';
import { Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import preprocessors from '../../Global/FormBuilder/Preprocessors/preprocessors.js';

import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

const columns =  [
	  { field: 'model_name', headerName: 'Model Name', width: 350,
		renderCell : function(params){
			
				var canEdit= !(params.row.approval_status === 'Approved');
				 return (
					<div className="row-icon-container">
						<div className="row-label">
							{params.row.model_name}
						</div>
						{ canEdit && (<Link title="Edit" to={'/model-master/approve/'+params.row.model_name}>
							<IconButton variant="contained">
								<CreateIcon className="ecl-edit-icon"/>
							</IconButton>
						</Link>) }
						<Link to={'/model-master/view/'+params.row.model_name} >
							<IconButton title="View" variant="contained">
								<VisibilityIcon  className="ecl-view-icon"/>
							</IconButton>
						</Link>
					</div>
				)
			}
	  },
	  { field: 'approval_status', headerName: 'Approval Status', width: 210,
			renderCell: function(params){
				if(params.row.approval_status === 'Draft'){
					return (<div className="ecl-status-draft ecl-status-div">Draft</div>)
				}
				if(params.row.approval_status === 'Pending'){
					return (<div className="ecl-status-pending ecl-status-div">Pending</div>)
				}
				if(params.row.approval_status === 'Rejected'){
					return (<div className="ecl-status-rejected ecl-status-div">Rejected</div>)
				}				
				if(params.row.approval_status === 'Approved'){
					return (<div className="ecl-status-approved ecl-status-div">Approved</div>)
				}
			}
	  },
	  { field: 'created_user', headerName: 'Created User', width: 210 },	  
	  { field: 'created_time', headerName: 'Created Time', width: 210, type: 'date',
			valueFormatter: (params) => {
				return preprocessors.date(params.value, ' ');
			} },	  	  
	  { field: 'approved_user', headerName: 'Approved User', width: 210 },	  
	  { field: 'approved_time', headerName: 'Approved Time', width: 210, type: 'date',
			valueFormatter: (params) => {
				return preprocessors.date(params.value, ' ');
			} }
];

function ModelMasterApproveList(props) {
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title ecl-header-title">
				Approve Master Model
				<Breadcrumbs aria-label="breadcrumb">
					<Typography color="textPrimary">
					Master Model (Approve List)
					</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Master Models
				</Typography>
				<div className="ecl-list-grid-container">
					 <DataGrid rows={(props.data || [])} columns={columns} />
				</div>
			</Card>
		</div>
	);
}

export default dataUIWrapper(ModelMasterApproveList);