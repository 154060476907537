import React from 'react';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Typography from '@material-ui/core/Typography';
// import { DataGrid } from '@material-ui/data-grid';
import Card from '@material-ui/core/Card';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import {config} from '../config/UserProfile.js';
// import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
// import { Link} from "react-router-dom";
// import Button from '@material-ui/core/Button';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import {variableMasterData} from '../data/variableMasterData.js';
// import APIConfig from '../../../APIConfig.js'
// import preprocessors from '../../Global/FormBuilder/Preprocessors/preprocessors.js';


function UserProfile(props) {
	
	var _data = (props.data || []);

	var userinfo=(_data.message || [])

	return (
		<div className="right-section-contents">
			<div className="content-heading">
				<Typography variant="h4" className="app-header-title ecl-header-title">
					User Profile
				</Typography>
			</div>
			<Card className="main-ui-card">
				<div className="user-flex">
					<div className="user-icon-container">
						<AccountCircleIcon className="user-profile-icon" />
					</div>
					<div className="user-div">
						<Typography variant="h3">
							{userinfo.first_name} {userinfo.last_name}
						</Typography>
						<div className="user-sub-divs">
							<Typography variant="h6">
								{userinfo.username}	
							</Typography>
							<Typography variant="h6">
								{userinfo.email}
							</Typography>
						</div>
					</div>
				</div>
				
				{/* <FormBuilder {...config} data={_data[1] || []} onUpdateForm = {(e)=> {_data[1]=e} }/> */}
			</Card>
		</div>
	);
}

export default dataUIWrapper(UserProfile);

