import React from 'react';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import preprocessors from '../../Global/FormBuilder/Preprocessors/preprocessors.js';
import Card from '@material-ui/core/Card';

const columns =  [
	  { field: 'audit_module', headerName: 'Audit Module',  width: 200},
	  { field: 'item_name', headerName: 'Item Name',  width: 200},
	  { field: 'change_type', headerName: 'Change Type',  width: 200},
	  { field: 'change_status', headerName: 'Change Status', width: 200},
	  { field: 'change_message', headerName: 'Change Message', width: 500},
	  { field: 'updated_by', headerName: 'Updated By',  width: 200},  
	  { field: 'updated_time', headerName: 'Updated Time', width: 200, type: 'date',
			valueFormatter: (params) => {
				return preprocessors.date(params.value, ' ');
			}},  
	  { field: 'created_user_ip_address', headerName: 'IP Address',  width: 200}
	  
];

function AuditTrailList(props) {
	
	
	return (
		<div className="right-section-contents">
			<div className="content-heading">
				<Typography variant="h4" className="app-header-title ecl-header-title">
					List Audit Trail
					{/* <Breadcrumbs aria-label="breadcrumb">
						<Typography color="textPrimary">
						Master Model (All List)
						</Typography>
					</Breadcrumbs> */}
				</Typography>
			</div>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Audit Trail
				</Typography>
				<div className="ecl-list-grid-container">
					 <DataGrid rows={(props.data || [])} columns={columns} />
				</div>
			</Card>
		</div>
	);
}

export default dataUIWrapper(AuditTrailList);