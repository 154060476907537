import React from 'react';
import Typography from '@material-ui/core/Typography';

function LogoutController() {
	
	
	return (
		<div className="full-container login-container">
			<div className="login-form-wrapper">
				<div className="login-spacer">
					<Typography variant="h4">
						Logged Out
					</Typography>
				</div>
				<div>
						You have been successfully logged Out. Kindly wait you will be redirected to login screen in a bit.
				</div>
			</div>
		</div>
	);
}

export {
	LogoutController
}