import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

function FormDropDown(props) {
	
	const [value, setValue] = React.useState(props.defaultValue || '');

	React.useEffect(() => {
	   setValue(props.defaultValue || '');
	}, [props.defaultValue]);
	
	const handleChange = function(event){
		setValue(event.target.value);
		if(props.onUpdateValue){
			props.onUpdateValue(event.target.value, props.objectKey);
		}
	}

	const getMenuItems = function(data){
		return data.map(function(_d, idx){
			return ( <MenuItem key={idx} value={_d.value}>{_d.text}</MenuItem>)
		});
	}

	return (
		<Select
			id={(props.id || props.objectKey)}
			value = {value}
			onChange = {handleChange}
			{...props.widgetProps}
			variant="filled"	
		>
			{getMenuItems(props.data)}
		</Select>
	);
}

export {
	FormDropDown
}