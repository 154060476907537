var modelNameModel = {
	objectKey: 'model_name',
	widget: 'FormDropDown',
	text: 'Model Name',
	data: []
};

var config = {
	modelNameObj: modelNameModel,
	config: [
		[
			modelNameModel
			,
            // {
			// 	objectKey: 'model_definition_name',
			// 	widget: 'FormTextInput',
			// 	text: 'Definition Name',
			// },
        ]
    ]
}


export {
	config
}