import React from 'react';
import APIConfig from '../../APIConfig.js'
import  { useHistory } from 'react-router-dom';


const AdminView = function(props){

    
    return (<div className="right-section-contents admin-contents">
            <iframe src={APIConfig.adminURL} className="admin-iframe"/>
           </div>)

}

export default AdminView;