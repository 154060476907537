import React from 'react';
import {config} from '../config/ScenarioDefinitionCreate.js';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import ScenarioWeightDataGrid from '../../Global/ScenarioWeightDataGrid.js'
import VariableCurrentMVDataGrid from '../../Global/VariableCurrentMVDataGrid.js'
import ScenarioYearwiseDataGrid from '../../Global/ScenarioYearwiseDataGrid.js'
import {notificationObj} from '../../Global/NotificationHandler.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  { Redirect } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

var getDefaultData = function(){ return{send_for_approval: false, no_of_scenarios:1,  no_of_years:7} }

function ScenarioDefinitionCreate(props) {
	
	const [apiConf, setApiRef] = React.useState({});
	const [selectedScenerio, setSelectedScenerio] = React.useState(0);
	const [scenerioYearWiseData, setScenerioYearwiseData] = React.useState([]);	
	const [scenerioWeights, setScenerioWeights] = React.useState([
		{ id:1, scenario_name: "Scenario 1" },
		{ id:2, scenario_name: "Scenario 2" },
		{ id:3, scenario_name: "Scenario 3" },
		{ id:4, scenario_name: "Scenario 4" },
		{ id:5, scenario_name: "Scenario 5" },
	]);	
	
	const [created, setCreated] = React.useState(false);
	
	const [numberOfYears, setNoOfYears] = React.useState(7);
	const [numberOfScenerios, setNumberOfScenerios] = React.useState(1);
	
	const formData = React.useMemo(getDefaultData, []);
	
	React.useEffect(function(){
		if(props.models){
			config.modelDefinitionNameObj.data = props.models.map(function(_model){
				return {
					text: _model.model_definition_name,
					value: _model.model_definition_name
				}
			});
		}
	},[props.models]);
	
	const updateFormData = React.useCallback(function(key, value){
		
		if(key === "model_definition_name"){
			props.dispatchRequest('fetchVariables', {model_definition_name: value} ).then(function(e){
				if(e.data && e.data[1]){
					var _data = [[],[],[],[],[], []];
					
					e.data[1].forEach(function(_def){
						if(_def.variable_selected_yn === 'TRUE'){
							
							
							for(var i=0;i<6;++i){
								_data[i].push({
									id: _def.id,
									variable_name: _def.variable_name
								});
							}
						}
					});
					
					setScenerioYearwiseData(_data || []);
				}
			});
		}else if(key === "no_of_years"){
			setNoOfYears(Number(value))
		}else if(key === "no_of_scenarios"){
			setNumberOfScenerios(Number(value))
		}
		
	},[props]);
	
	const handleScenerioChange = React.useCallback(function(e, newValue){
		setSelectedScenerio(newValue);
	},[]);
	
	const ScenerioTabs = React.useMemo(function(){
		var _tabs = [];
		
		for(var i =0;i<numberOfScenerios; i++){
			_tabs.push(<Tab label={"Scenario "+(i+1)} key={i}/>);
		}
		
		return (<React.Fragment>
			<AppBar position="static" className="card-tabs">
				<Tabs value={selectedScenerio} onChange={handleScenerioChange} aria-label="simple tabs example">
					{_tabs}
				</Tabs>
			</AppBar>
			<div className="ecl-grid-container">
				<ScenarioYearwiseDataGrid  numberOfYears={numberOfYears} className="scenario-yearwise-grid variable-grid" rows={(scenerioYearWiseData[selectedScenerio] || [])} />
			</div>
		</React.Fragment>)
	},[numberOfScenerios, numberOfYears, selectedScenerio, scenerioYearWiseData], handleScenerioChange);
	
	const createClick = React.useCallback(function(){
		
		var formData = apiConf.getData(),
			isError = false;
		
		if(!formData.model_definition_name){
			notificationObj.showNotification('error','Select model definition');
			return;
		}
		if(!formData.scenario_definition_name){
			notificationObj.showNotification('error','Enter scenario name');
			return;
		}
		
		var scenerioMV = scenerioYearWiseData[5].forEach(function(_row){
			if(!_row.variable_current_mv){
				isError = 'Enter scenerio variable MV value';
			}
		});
		
		if(isError){
			notificationObj.showNotification('error',isError);
			return;
		}
		
		var total = 0;
		
		scenerioWeights.slice(0, numberOfScenerios).forEach(function(_row){
			if(isError){
				return false;
			}
			if(!_row.scenario_weight_value){
				isError = 'Enter scenerio weights';
			}
			total=total +Number(_row.scenario_weight_value)*1000;
		});
		
		if(isError){
			notificationObj.showNotification('error',isError);
			return;
		}
		
		
		if(total!==1000){
			notificationObj.showNotification('error','Sum of weight value should equal to 1.0');
			return;
		}
		
		
		var allscenarions = scenerioYearWiseData.slice(0, numberOfScenerios).map(function(_scList, idx){
			
			return {
				"scenario_name": "Scenario "+(idx+1),
				"variables": _scList.map(function(_sc){
					
					var years = {};
					for(var i =1; i <= numberOfYears; ++i){
						years[i] = _sc['year'+i] || 0;
					}
					return {
						"variable_name" : _sc.variable_name,
						"years": years
					}				
				})
			}
		});
		
		var obj= {
			... formData,
			Scenario_CurrentMVs: scenerioYearWiseData[5],
			Scenario_Weights: scenerioWeights.slice(0, numberOfScenerios),
			allscenarions
		}
		debugger;
		props.dispatchRequest('create', {data: obj} ).then(function(){
			setCreated(true); 
		});
		
	},[props, scenerioYearWiseData, scenerioWeights, numberOfScenerios, numberOfYears, apiConf])
	
	if(created){
		return (<Redirect to='/scenario-definition/list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Create Scenario Definition
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/scenario-definition/list/">
					Scenario Definition (All List)
					</Link>
					<Typography color="textPrimary">Create</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Scenario Definition
				</Typography>
				<FormBuilder {...config} onUpdate = { updateFormData }  data= { formData } apiConf={apiConf} />
			</Card>
			<div className="card-flex-column">
				<Card className="main-ui-card">
					<Typography variant="h6" className="app-sub-title ecl-header-title">
						Variable Current MV Values
					</Typography>
					
					<div className="ecl-grid-container">
						 <VariableCurrentMVDataGrid  className="variable-current-mv-grid variable-grid" rows={(scenerioYearWiseData[5] || [])} />
					</div>
				</Card>

				<Card className="main-ui-card">
					<Typography variant="h6" className="app-sub-title ecl-header-title">
						Scenario Weights (Sum of weight value should equal to 1.0)
					</Typography>
					
					<div className="ecl-grid-container">
						 <ScenarioWeightDataGrid  className="scenario-weight-grid variable-grid" rows={scenerioWeights.slice(0, numberOfScenerios)} />
					</div>
				</Card>
			</div>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Scenario Year Wise
				</Typography>
				{ScenerioTabs}
			</Card>
			
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick = {createClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ScenarioDefinitionCreate);