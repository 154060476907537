import React from 'react';
import ScenarioDefinitionCreate from './Routes/ScenarioDefinitionCreate.js';
import ScenarioDefinitionList from './Routes/ScenarioDefinitionList.js';
import ScenarioDefinitionEdit from './Routes/ScenarioDefinitionEdit.js';
import ScenarioDefinitionApprove from './Routes/ScenarioDefinitionApprove.js';
import ScenarioDefinitionApproveList from './Routes/ScenarioDefinitionApproveList.js';
import ScenarioDefinitionView from './Routes/ScenarioDefinitionView.js';
import { Route} from 'react-router-dom';	
import APIConfig from '../../APIConfig.js'


var parseScenario = function(scenerios, Scenario_CurrentMVs){
	
	var _scenarios = [[],[],[],[],[]];
	scenerios.forEach(function(_s){
		var scenerioIndex = _s.scenario_name.split(' ')[1] - 1;
		_scenarios[scenerioIndex].push(_s);
	});
	
	_scenarios.forEach(function(_s){
		if(!_s.length){
			Scenario_CurrentMVs.forEach(function(_smv){
				_s.push({
					id: _smv.id,
					variable_name: _smv.variable_name
				})
			});
		}
	});
	
	return _scenarios;
}

function MainScenarioDefinitionRouter() {
	
	var listFetchingData = [
		{
			url: APIConfig.allScenarioDefinitionRecords,
			method: 'get',
			key: 'data'
		}
	];
	var approveListFetchingData = [
		{
			url: APIConfig.approveListScenarioDefinitionRecord,
			method: 'get',
			key: 'data'
		}
	];
	var editFetchingData = [
		{
			url: APIConfig.getScenarioDefinitionRecord,
			method: 'get',
			//key: 'data',
			preprocessor: function(_data){
				var currentModel = window.location.pathname.split('/').splice(-1);
				_data.url = _data.url+currentModel+'/';
				return _data;
			},
			postProcessor: function(dataObj, result){
				
					dataObj.formData = result.Scenario_Definition;
					dataObj.scenerioYearWiseData = parseScenario(result.Scenarios, result.Scenario_CurrentMVs)
					dataObj.variableMVData = result.Scenario_CurrentMVs;
					dataObj.scenerioWeights = result.Scenario_Weights;
			
			
			}
		}
	];
	
	var createDispatchers = {
		create: {
			method: 'post',
			url:  APIConfig.createScenarioDefinitionRecord,
		},
		fetchVariables: {
			url:  APIConfig.getModelDefinitionRecord,
			preprocessor: function(_obj, data){
				_obj.url = _obj.url + _obj.model_definition_name+'/';
				return _obj;
			}
		}
	}
	
	var editDispatchers = {
		edit: {
			method: 'put',
			url:  APIConfig.createScenarioDefinitionRecord,
		}
	}
	
	var listDispatchers = {
		delete: {
			method: 'delete',
			url:  APIConfig.deleteSceanrioDefinitionRecord,
			preprocessor: function(_obj){
				_obj.url  = _obj.url  +_obj.row.scenario_definition_name+'/';
				return _obj;
			}
		}
	}
	
	var approveDispatchers={
		approve: {
			method: 'put',
			url: APIConfig.approveScenarioDefinitionRecord
		}
	}
	
	
	
	
	var createFetcher = [
		{
			url: APIConfig.approvedDefinitions,
			key: 'models'
		}
	]
	
	return (
		<React.Fragment>
			<Route path="/scenario-definition/edit/:id">
				<ScenarioDefinitionEdit fetchingData={editFetchingData} dispatcher={editDispatchers}/>
			</Route>
			<Route path="/scenario-definition/view/:id">
				<ScenarioDefinitionView fetchingData={editFetchingData}/>
			</Route>
			<Route path="/scenario-definition/list">
				<ScenarioDefinitionList fetchingData={listFetchingData} dispatcher={listDispatchers} />
			</Route>
			<Route path="/scenario-definition/create">
				<ScenarioDefinitionCreate fetchingData={createFetcher} dispatcher={createDispatchers}/>
			</Route>
			<Route path="/scenario-definition/approve-list">
				<ScenarioDefinitionApproveList fetchingData={approveListFetchingData} dispatcher={listDispatchers}/>
			</Route>
			<Route path="/scenario-definition/approve/:id">
				<ScenarioDefinitionApprove fetchingData={editFetchingData} dispatcher={approveDispatchers}/>
			</Route>
		</React.Fragment>
	);
}

export {
	MainScenarioDefinitionRouter
}
