import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

function ScenarioYearwiseDataGrid(props) {
	
	const [rowData , setData] = React.useState(props.rows);
	var variableMap = {};
	
	React.useEffect (function(){
		setData(props.rows);
		variableMap = {};
		(rowData || []).forEach(function(_r){
			variableMap[_r.id] = _r;
		});
	},[props.rows]);
	
	
	(rowData || []).forEach(function(_r){
		variableMap[_r.id] = _r;
	});
		
	var columns=[
		{ field: 'variable_name', headerName: 'Variable Name',  width: 350}		
	];
	
	for(var i = 1;i<=props.numberOfYears;++i){
		columns.push({ field: ('year'+i), headerName: ('Yr'+i),  width: 150, type: 'number', editable: !props.preventEdit,  valueFormatter: (params) => {
			return params.value || '0';
		  }
	  })
	}
	
	
	const handleEditCellChangeCommitted = React.useCallback(
		({ id, field, props }) => {
			variableMap[id][field] = props.value;
		},
		[variableMap],
	  );
	
	return (
		<DataGrid className={props.className} rows={rowData} columns={columns} 
			onEditCellChangeCommitted={handleEditCellChangeCommitted} />
	);
}

export default ScenarioYearwiseDataGrid;