import React from 'react';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@material-ui/data-grid';
import Card from '@material-ui/core/Card';
import { Link} from "react-router-dom";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp'; 
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import preprocessors from '../../Global/FormBuilder/Preprocessors/preprocessors.js';
import saveConfigAsPDF from '../common/saveConfigAsPDF.js';
import VisibilityIcon from '@material-ui/icons/Visibility';

function ComputationList(props) {
	
	const download = React.useCallback(function(model_name){
		
		props.dispatchRequest('download', {model_name: model_name} ).then(function(e){
			saveConfigAsPDF(e.data, model_name)
		});
	},[props]);
	
	const columns =  [
	  { field: 'configuration_name', headerName: 'Configuration Name', width: 300,
		renderCell: function(params){
			
			var canDownload = !(params.row.Job_status === 'Failed' || params.row.Job_status === 'Running' || params.row.Job_status === 'Queued' );
			return (
				<div className="row-icon-container">
					<div className="row-label">
						{params.row.configuration_name}
					</div>
					<Link to={'/ecl-computation/view/'+params.row.configuration_name} >
						<IconButton title="View" variant="contained" >
							<VisibilityIcon className="ecl-view-icon"/>
						</IconButton>
					</Link>
					{canDownload && (<Link to="#">
						<IconButton title="Download"  className="highlight-icon" variant="contained"  onClick={(e)=>  {download(params.row.configuration_name)}}>
							<GetAppIcon/>
						</IconButton>
					</Link>)}
				</div>
			)
			
		}
	  },
	  { field: 'scenario_definition_name', headerName: 'Scenario Definition Name', width: 200}, 
	  { field: 'Job_status', headerName: 'Job Status', width: 200,
			renderCell: function(params){
				if(params.row.Job_status === 'Queued'){
					return (<div className="ecl-status-pending ecl-status-div">Queued</div>)
				}
				if(params.row.Job_status === 'Running'){
					return (<div className="ecl-status-draft ecl-status-div">Running</div>)
				}
				if(params.row.Job_status === 'Failed'){
					return (<div className="ecl-status-rejected ecl-status-div">Failed</div>)
				}				
				if(params.row.Job_status === 'Completed'){
					return (<div className="ecl-status-approved ecl-status-div">Completed</div>)
				}
			}
	  
	  }, 	  
	  { field: 'reporting_time', headerName: 'Reporting Date', type: 'date', width: 200,
			valueFormatter: (params) => {
				return preprocessors.date(params.value, ' ');
			}
	  },	  
	  { field: 'Job_comments', headerName: 'Job Comments', width: 500}, 	  
	  { field: 'created_user', headerName: 'Created User', width: 200 },	  
	  { field: 'created_time', headerName: 'Created Time', type: 'date', width: 200,
			valueFormatter: (params) => {
				return preprocessors.date(params.value, ' ');
			}
	  }
	];
	
	
	return (
		<div className="right-section-contents">
			<div className="content-heading">
				<Typography variant="h4" className="app-header-title ecl-header-title">
					List Configuration
					<Breadcrumbs aria-label="breadcrumb">
						<Typography color="textPrimary">
						Configuration (All List)
						</Typography>
					</Breadcrumbs>
				</Typography>
				<Link to={'/ecl-computation/create/'} >
					<Button variant="contained" color="primary" className="heading-button" >
						Create Configuration					
					</Button>
				</Link>
			</div>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Configurations
				</Typography>
				<div className="ecl-list-grid-container">
					 <DataGrid rows={(props.data || [])} columns={columns} />
				</div>
				<div id="pdfDiv"></div>
			</Card>
		</div>
	);
}

export default dataUIWrapper(ComputationList);