import React from 'react';
import {widgets} from './FormWidgets.js';
import preprocessors from './Preprocessors/preprocessors.js';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';


var FormObject = function(){

	this.object = {
		
	}
	
	this.setObj = function(obj){
		
		this.object = {
			...this.object,
			...obj
		}
		
	}
	
	
	this.set = function(key, value){
		this.object[key] = value;
		this.lastUpdated = Date.now();
	}
	
	this.get = function(){
		return this.object;
	}
	
}


var getFormObject = function(){
	return new FormObject();
}



const FormBuilder = React.forwardRef((props, ref)=> {

  const refFormObject = React.useMemo(()=> getFormObject(),[]);
  const [configState, setConfigState] = React.useState(props.config);
  
  
  React.useEffect(()=>{
		refFormObject.setObj(props.data)
  },[props.data, refFormObject]);
  
  React.useEffect(function(){
	  setConfigState(props.config);
  },[props.config]);
    
  React.useEffect(function(){
	  if(props.apiConf){
			props.apiConf.getData = function(data){
				return refFormObject.get();
			}
			props.apiConf.ref = function(data){
				return refFormObject;
			}
	  }
  },[props.apiConf, refFormObject]);
  
  const updateObject = function(value, key){
	refFormObject.set(key, value);
	if(typeof props.onUpdate === 'function'){
		props.onUpdate(key, value)
	}
  }
  
  const getTooltip = function(tt){
	  if(tt){
		 return( <Tooltip title={tt}>
			<InfoIcon/>
		  </Tooltip> )
	  }else{
		return null;  
	  }
  }
  
  const GetForm = function(_props){
	var config = _props.config
	return config.map(function(_conf, idx){
		var TagName = widgets[_conf.widget],
			key = (_conf.id || _conf.objectKey|| idx),
			defaultValue = (refFormObject.get())[_conf.objectKey];
		
		if(_conf.preprocessors && preprocessors[_conf.preprocessors]){
			defaultValue = preprocessors[_conf.preprocessors](defaultValue)
		}
		
		if(_conf.objectKey){
			//formObject[_conf.objectKey] = typeof defaultValue ==='undefined'?'':defaultValue;
			refFormObject.set(_conf.objectKey,(typeof defaultValue ==='undefined'?'':defaultValue));
		}
		
		return (
			<div key={key} className="form-builder-form-wrapper">
				<div className="form-label">
				{_conf.text}
				</div>
				<div className={"form-input " + (_conf.tooltip?'tooltip-form':'')}>
					<TagName {..._conf} defaultValue={defaultValue} onUpdateValue={updateObject}/>
					{getTooltip(_conf.tooltip)}
				</div>
			</div>
		)
	});
	
		
  }
  
  
  const processForm = function(config){
	  
	 var _map = config.map(function(_c, idx){
		 return (
			<div key={idx} className="ecl-form-builder-column">
				<GetForm config={_c}/>			
			</div>
		 )
		  
	  });
	  return _map;
	  
  }
  
  return (
    <div ref={ref} className="ecl-form-builder-wrapper">
		{processForm(configState)}
	</div>
  );

})

export {
	FormBuilder
}