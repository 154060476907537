var modelNameModel = {
	objectKey: 'model_name',
	widget: 'FormDropDown',
	text: 'Model Name',
	data: []
};

var config = {
	modelNameObj: modelNameModel,
	config: [
		[
			modelNameModel
			,
            {
				objectKey: 'model_definition_name',
				widget: 'FormTextInput',
				text: 'Definition Name',
			},
			{
				objectKey: 'model_type',
				widget: 'FormTextInput',
				text: 'Model Type',
			},
			{
				objectKey: 'intercept_y_value',
				widget: 'FormTextInput',
				text: 'Intercept Y Value',
				tooltip: 'Any numeric value, positive or negative',
				widgetProps: {
					type: "number",
					
				}
			},
            
		],
		[
			{
				objectKey: 'send_for_approval',
				widget: 'FormSwitch',
				text: 'Send for Approval',
				tooltip: 'Send for Approval will change approval status to Pending for approval',
			}			
			
		]
	]
}


export {
	config
}