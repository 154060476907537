import React from 'react';
import UserProfile from './Routes/UserProfile';
import { Route} from 'react-router-dom';	
import APIConfig from '../../APIConfig.js'


function MainUserProfileRouter() {
	
	var listFetchingData = [
		{
			url: APIConfig.getuserinfo,
			method: 'get',
			key: 'data'
		}
	];
	
	
	return (
		<React.Fragment>
			<Route path="/userprofile/">
				<UserProfile fetchingData={listFetchingData} />
			</Route>
		</React.Fragment>
	);
}

export {
	MainUserProfileRouter
}
