import React from 'react';

function Spacer(props) {

	return (
		<div className="spacer"/>
	);
}

export {
	Spacer
}