import React from 'react';
import Card from '@material-ui/core/Card';
import WarningIcon from '@material-ui/icons/Warning';
import Button from '@material-ui/core/Button';

var notificationObj = null;
var notificationIndex = 0;

function withNotificationHandler(MainComponent) {
	
	if(notificationObj){
		throw new Error('Already Initialized');
	}
	
	return class NewNotificationWrapper extends React.Component{
		
		constructor(props){
			super(props);	
			notificationObj = this;
			this.state={
				notifications: [],
				showConfirm: false,
				confirmObject: {}
			}
		}
		
		showConfirmBox(object){
			if(object){
				this.setState({
					showConfirm: true,
					confirmObject: object
				})
			}else{
				this.setState({
					showConfirm: false,
					confirmObject: {}
				})
			}
			
		}
		
		showNotification(type, message){
			var notifications = this.state.notifications.slice();
			notifications.push({
				idx: notificationIndex++,
				type: type,
				message: message
			});
			this.setState({
				notifications: notifications
			});
		}
		
		
		removeNotification(obj){
			var notifications = this.state.notifications.slice();
			var index = notifications.indexOf(obj);
			notifications.splice(index, 1)
			this.setState({
				notifications: notifications
			});
		}
		
		renderNotifications(){
			var that=this;
			var notifications = (this.state.notifications).slice();
			
			
			return notifications.map(function(_n){
				var item = (<div key={_n.idx} className={"ecl-fade-in ecl-notification ecl-notification-"+_n.type}>{_n.message}</div>)
				
				if(!_n.timeout){
					_n.timeout = setTimeout(function(){
						that.removeNotification(_n);
					},10000)
				}
				
				return item;
			});
			
		}
		
		handleConfirmClick(_of){
			this.setState({
				showConfirm: false
			});
			if(this.state.confirmObject[_of]){
				this.state.confirmObject[_of]();
			}
			
		}
		
		renderConfirmBox(){
			if(this.state.showConfirm){
				return (
					<div className={"ecl-confirm-box-container"}>
						<Card className="confirm-box-card">
							<div className="confirm-box-contents">
								<div className="confirm-icon">
									<WarningIcon />
								</div>
								<div className="confirm-text">
									<div className="confirm-header">
									{this.state.confirmObject.title || 'Confirm !'}
									</div>
									<div className="confirm-description">
									{this.state.confirmObject.description || ''}
									</div>
								</div>
							</div>
							<div className="confirm-box-buttons">
								<Button variant="contained" color="primary" onClick={()=>this.handleConfirmClick('ok')} disableElevation>Ok</Button>
								<Button variant="contained" disableElevation onClick={()=>this.handleConfirmClick('cancel')} className="margin-left">Cancel</Button>
								
							</div>
						</Card>
					</div>
				)
			}
			
			return null;
		}
		
		render(){
			return (
				<React.Fragment>
					<MainComponent/>
					{this.renderConfirmBox()}
					<div className={"ecl-notification-container"}>
						<div className={"ecl-notification-wrapper"}>
						{this.renderNotifications()}
						</div>
					</div>
				</React.Fragment>
			)
		}
		
		
	}
	
	
}

export {
	withNotificationHandler,
	notificationObj
};