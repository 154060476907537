var config = {
	config: [
		[
			{
				objectKey: 'model_name',
				widget: 'FormTextInput',
				text: 'Model Name',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'model_definition_name',
				widget: 'FormTextInput',
				text: 'Model Definition Name',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'model_type',
				widget: 'FormTextInput',
				text: 'Model Type',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'intercept_y_value',
				widget: 'FormTextInput',
				text: 'Intercept Y Value',
				widgetProps: {
					disabled: true
				},
			},
			{
				widget: 'Spacer',
			},
			{
				objectKey: 'created_user',
				widget: 'FormTextInput',
				text: 'Created User',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'created_time',
				widget: 'FormDateTimeInput',
				preprocessors:'date',
				text: 'Created Time',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'created_user_ip_address',
				widget: 'FormTextInput',
				text: 'IP Address',
				widgetProps: {
					disabled: true
				},
			},
		],
		[
			
			
			{
				objectKey: 'updated_user',
				widget: 'FormTextInput',
				text: 'Updated User',
				widgetProps: {
					disabled: true
				},
			},
			{
				objectKey: 'updated_time',
				preprocessors:'date',
				widget: 'FormDateTimeInput',
				text: 'Updated Time',
				widgetProps: {
					disabled: true
				},
			},
			{
				widget: 'Spacer',
			},
			{
				objectKey: 'approval_status',
				widget: 'FormRadio',
				text: 'Approval Status',
				data: [
					{
						value: 'Approved',
						text: 'Approved',
						tooltip: 'Approved model cannot be edited'
					},
					{
						value: 'Rejected',
						text: 'Rejected',
						tooltip: 'Rejected model will change status to Draft'
					}
				]
			},
			{
				objectKey: 'approved_user_comment',
				widget: 'FormMultilineInput',
				text: 'Approved Comments'
			},
		]
	]
}


export {
	config
}