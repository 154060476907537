import React from 'react';
import {config} from '../config/DashboardView';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import  { Redirect } from 'react-router-dom';
import {
  Chart,
  PieSeries,
  Title,  
  Legend,
    ArgumentAxis,
  ValueAxis,
  BarSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation } from '@devexpress/dx-react-chart';
import { EventTracker } from '@devexpress/dx-react-chart';
import { DataGrid } from '@material-ui/data-grid';

function ModelChartReport(props) {
	
	const [aggregateMasterData, setAggregateMasterData] = React.useState([]);
	const [aggregateDefinitionData, setAggregateDefinitionData] = React.useState([]);
	const [aggregateScenarioData, setAggregateScenarioData] = React.useState([]);
	const [reportsData, setreportsData] = React.useState([]);
	
	React.useEffect(function(){
		var _aggrmaster,_aggrdefinitions, _aggrscenario,_repr=[];


		console.log(props.reportsData);
		if(props.modelsData){
			_aggrmaster = [
				{title: 'Approved'+ '(' + props.modelsData.masterapprovecount+')', value: props.modelsData.masterapprovecount},
				{title: 'Pending'+'('+props.modelsData.masterpendingcount+')', value: props.modelsData.masterpendingcount},
				{title: 'Draft' +'('+props.modelsData.masterdraftcount+')', value: props.modelsData.masterdraftcount},
				{title: 'Rejected'+'('+props.modelsData.masterrejectcount+')', value: props.modelsData.masterrejectcount}
			] 
			_aggrdefinitions=[
				{title: 'Approved'+'('+props.modelsData.definitionapprovecount+')', value: props.modelsData.definitionapprovecount},
				{title: 'Pending'+'('+props.modelsData.definitionpendingcount+')', value: props.modelsData.definitionpendingcount},
				{title: 'Draft'+'('+props.modelsData.definitiondraftcount+')', value: props.modelsData.definitiondraftcount},
				{title: 'Rejected'+'('+props.modelsData.definitionrejectcount+')', value: props.modelsData.definitionrejectcount}
			]
			_aggrscenario=[
				{title: 'Approved'+'('+props.modelsData.scenarioapprovecount+')', value: props.modelsData.scenarioapprovecount},
				{title: 'Pending'+'('+props.modelsData.scenariopendingcount+')', value: props.modelsData.scenariopendingcount},
				{title: 'Draft'+'('+props.modelsData.scenariodraftcount+')', value: props.modelsData.scenariodraftcount},
				{title: 'Rejected'+'('+props.modelsData.scenariorejectcount+')', value: props.modelsData.scenariorejectcount}
			]
			// if(props.reportsData) {
			// _repr=[
			// 	{title: props.reportsData.scenario_definition_name, value: props.modelsData.scenarioapprovecount},
			// ]
			// }	
			if(props.reportsData){
				props.reportsData.forEach(function(_model, idx){
					_repr.push(
							{
								id: idx,
								title: String(_model.scenario_definition_name)+' ('+Number(_model.scenario_executions)+')', 
								value:  Number(_model.scenario_executions),
							}
					);
			
				});
			}

			setAggregateMasterData(_aggrmaster);
			setAggregateDefinitionData(_aggrdefinitions);
			setAggregateScenarioData(_aggrscenario);
			setreportsData(_repr);
		}
		
		
	},[props.modelsData]);

	// const getBarChartReports = function(){
	// 	var reports = [];

	// 	reportsData.forEach(function(_obj, idx){
	// 		debugger;
	// 		_obj.scenario_executions = Number(_obj.scenario_executions);			
	// 		reports.push(
	// 			(
	// 			<Card key={idx} idx={idx} className="main-ui-card inline-card">
	// 				<Chart
	// 					data={[_obj]}
	// 					height={400}
	// 					width={400}
	// 				>
	// 					<ArgumentAxis />
	// 					<ValueAxis />
	// 					<BarSeries
	// 						name="ECL Executions"
	// 						valueField="scenario_executions"
	// 						argumentField="title"
	// 					  />
	// 					<Legend  position="bottom"/>
	// 					{/* <Stack /> */}
	// 					<Animation />
	// 					<EventTracker />
	// 					<Tooltip />
						
	// 				</Chart>
	// 			</Card>
	// 			)		
	// 		)
			
	// 	});
		
	// 	return reports;
		
	// }
	
	return (
		<React.Fragment>
			<div className="card-flex-column">
				<Card className="main-ui-card">
					<Typography variant="h5" className="app-header-title  ecl-header-title">
						Master Models
					</Typography>
					<Chart
						className={'main-model-chart'}
					  data={aggregateMasterData} 
					>
						  <PieSeries
							valueField="value"
							argumentField="title"
						  />
						   <Legend  position="right"/>
						  <Animation />
						  <EventTracker />
						<Tooltip />
					</Chart>
				</Card>
				<Card className="main-ui-card">
					<Typography variant="h5" className="app-header-title  ecl-header-title">
						Model Definitions
					</Typography>
					<Chart
						className={'main-model-chart'}
					  data={aggregateDefinitionData} 
					>
						  <PieSeries
							valueField="value"
							argumentField="title"
						  />
						   <Legend  position="right"/>
						  <Animation />
						  <EventTracker />
						<Tooltip />
					</Chart>
				</Card>
				<Card className="main-ui-card">
					<Typography variant="h5" className="app-header-title  ecl-header-title">
						Scenarios
					</Typography>
					<Chart
						className={'main-model-chart'}
					  data={aggregateScenarioData} 
					>
						  <PieSeries
							valueField="value"
							argumentField="title"
						  />
						   <Legend  position="right"/>
						  <Animation />
						  <EventTracker />
						<Tooltip />
					</Chart>
				</Card>
			</div>
			<div className="card-flex-column">
				<Card className="main-ui-card">
					<Typography variant="h5" className="app-header-title  ecl-header-title">
						ECL Executions
					</Typography>
					<Chart
						className={'main-model-chart'}
					  data={reportsData} 
					>
						  <PieSeries
							valueField="value"
							argumentField="title"
						  />
						   <Legend  position="right"/>
						  <Animation />
						  <EventTracker />
						<Tooltip />
					</Chart>
				</Card>
			</div>
			{/* <div className="inline-chart-container">
			<Typography variant="h5" className="app-header-title  ecl-header-title">
				ECL Executions 
			</Typography>
			{  getBarChartReports() }
			</div> */}

		</React.Fragment>
	);
}

export default ModelChartReport;