import React from 'react';
import {config} from '../config/ComputationCreate';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import {notificationObj} from '../../Global/NotificationHandler.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  { Redirect } from 'react-router-dom';



function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function ComputationCreate(props) {
	
	
	const [apiConf, setApiRef] = React.useState({});
	const [created, setCreated] = React.useState(false);
	const [defaultData, setDefaultData] = React.useState({});
	
	config.dispatcher = React.useCallback(function(){
		
		var formData = apiConf.getData();
		
		if(!formData.scenario_definition_name){
			notificationObj.showNotification('error','Select scenario definition name');
			return;
		}
		
		props.dispatchRequest('getCSV', {scenario: formData.scenario_definition_name}).then(function(e){
			download('sample_configuration_data.csv',e.data)
		});
		
	},[apiConf, props]);
	
	React.useEffect(function(){
		if(props.master){
			config.modelNameObj.data = props.master.map(function(_model){
				return {
					text: _model.model_name,
					value: _model.model_name
				}
			});
		}
	},[props.master]);
	
	const updateForm = React.useCallback(function(key, value){
		
		var formData = apiConf.getData();
		
		var allPresent = !(formData.scenario_definition_name && formData.ConfigurationName && formData.reporting_time),
			toUpdate = false;
		
		if(allPresent !== config.csvWidgetProp.disabled){
			config.csvWidgetProp.disabled = allPresent;
			apiConf.ref().setObj({
				csv_file_name: null
			});
			toUpdate= true;
		}
		
		if(key === 'model_name' ) {
			if(props.definintions){
				config.modelDefinitionNameObj.data = props.definintions.map(function(_model){
					
					if(_model.model_name === value){
						return {
							text: _model.model_definition_name,
							value: _model.model_definition_name
						}
					}
					return null;
					
				}).filter( (x)=> x );
				
			}
			config.scenarioDefinitionNameObj.data=[];
			apiConf.ref().setObj({
				model_definition_name: '',
				scenario_definition_name: '',
				csv_file_name: null
			});
			toUpdate= true;
			
		}else if(key === 'model_definition_name' ) {
			
			if(props.models){
				config.scenarioDefinitionNameObj.data = props.models.map(function(_model){
					if(_model.model_definition_name === value){
						return {
							text: _model.scenario_definition_name,
							value: _model.scenario_definition_name
						}
					}
					return null;
				}).filter( (x)=> x );
			}
			apiConf.ref().setObj({
				scenario_definition_name: '',
				csv_file_name: null
			});
			toUpdate= true;
		}
		
		if(toUpdate){
			setDefaultData(apiConf.getData());
		}
		
		
	},[props, apiConf]);

	
	const createClick = React.useCallback(function(){
		
		var formData = apiConf.getData(),
			isError= false;
			
		if(!formData.scenario_definition_name){
			isError = 'Enter scenario definition name';
		}else if(!formData.ConfigurationName){
			isError = 'Enter configuration name';
		}else if(!formData.reporting_time){
			isError = 'Enter reporting date and time';
		}else if(!formData.csv_file_name){
			isError = 'Provide CSV file name';
		}
		
		if(isError){
			apiConf.ref().setObj({
				csv_file_name: null
			});
			notificationObj.showNotification('error',isError);
			return;
		}
		
		var form_data = new FormData();
		Object.keys(formData).forEach(function(_key){
			form_data.append(_key, formData[_key]);
		});
		
		apiConf.ref().setObj({
			csv_file_name: null
		});
		
		props.dispatchRequest('create', {
			data: form_data,  
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		} ).then(function(){
			setCreated(true); 
		});
		
	},[props, apiConf]);
	
	
	if(created){
		return (<Redirect to='/ecl-computation/list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Create Configuration
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/ecl-computation/list">
					Configurations (All List)
					</Link>
					<Typography color="textPrimary">Create</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Configuration
				</Typography>
				<FormBuilder {...config}  onUpdate={updateForm} data= { defaultData } apiConf={apiConf}/>
			</Card>
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick = {createClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ComputationCreate);