import TextField from '@material-ui/core/TextField';
import React from 'react';

function FormDateTimeInput(props) {
	
	const [value, setValue] = React.useState(props.defaultValue || '');

	React.useEffect(() => {
	   setValue(props.defaultValue || '');
	}, [props.defaultValue]);

	const handleChange = function(event){
		setValue(event.target.value);
		if(props.onUpdateValue){
			props.onUpdateValue(event.target.value, props.objectKey);
		}
	}

	return (
		<TextField
			id={(props.id || props.objectKey)}
			value = {value}
			type="datetime-local"
			onChange = {handleChange}
			{...props.widgetProps}
			variant="filled"
		></TextField>
	);
}

export {
	FormDateTimeInput
}