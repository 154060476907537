import React from 'react';
import {NavigationBar} from './Widgets/Global/NavigationBar.js';
import ApplicationHeader from './Widgets/Global/ApplicationHeader.js';
import {MainRouter} from './Widgets/Global/MainRouter.js';
import {withNotificationHandler} from './Widgets/Global/NotificationHandler.js';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import APIConfig from './APIConfig.js';
import { loginStatus, performLogout } from './data/globalData/slices/userSlice';
import { useReactiveVar } from '@apollo/client';

const headerDispatchers = {
	logout: {
		method: 'post',
		url: APIConfig.logout
	},
}

const userFetchingData = [
	{
		url: APIConfig.getuserinfo,
		method: 'get',
		key: 'user'
	}
];

function App() {
	
  
	const userLoginStatus = useReactiveVar(loginStatus);
	
	const headerComponent = React.useMemo(function(){
		if(userLoginStatus.status==='logged'){
			return (<ApplicationHeader fetchingData={userFetchingData} dispatcher={headerDispatchers}/>)
		}
		return null;
	},[userLoginStatus]);
	
 
	return (
		<div className="full-container">
			<Router>
				<div className="App">
					<NavigationBar/>
					<div className="right-section-bar">
						{ headerComponent }
						<MainRouter/>
						{	(<div className="ecl-footer">	&#169; 2021 / RiskMaC - Next Generation RiskTech Platform </div>)}												
					</div>
				</div>
			</Router>
		</div>
	);
}

export default withNotificationHandler(App);
                       