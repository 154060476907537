import React from 'react';
import ComputationCreate from './Routes/ComputationCreate.js';
import ComputationList from './Routes/ComputationList.js';
import ComputationView from './Routes/ComputationView.js';
import { Route} from 'react-router-dom';	
import APIConfig from '../../APIConfig.js'


function MainComputationRouter() {
	
	var listFetchingData = [
		{
			url: APIConfig.allCongigurationRecords,
			method: 'get',
			key: 'data'
		}
	];
	
	var editFetchingData = [
		{
			url: APIConfig.getConfigurationRecord,
			method: 'get',
			key: 'data',
			preprocessor: function(_data){
				var currentModel = window.location.pathname.split('/').splice(-1);
				_data.url = _data.url+currentModel+'/';
				return _data;
			}
		}
	];
	
	var createDispatchers = {
		create: {
			method: 'post',
			url:  APIConfig.createConfigurationRecord,
		},
		getCSV:{
			url:  APIConfig.getCSV,
			method: 'get',
			preprocessor: function(_obj){
				_obj.url  = _obj.url  +_obj.scenario+'/';
				return _obj;
			}
		},
		fetchModelName: {
			url:  APIConfig.getMasterRecords,
			preprocessor: function(_obj, data){
				_obj.url = _obj.url + _obj.model_name+'/';
				return _obj;
			}
		},
		fetchModelDefinitions:{
			url:  APIConfig.getModelDefinitionRecord,
			preprocessor: function(_obj, data){
				_obj.url = _obj.url + _obj.model_definition_name+'/';
				return _obj;
			}
		},
		fetchVariables: {
			url:  APIConfig.getScenarioDefinitionRecord,
			preprocessor: function(_obj, data){
				_obj.url = _obj.url + _obj.scenario_definition_name+'/';
				return _obj;
			}
		}
	}
	
	var createFetcher = [
		{
			url: APIConfig.approvedScenarios,
			key: 'models'
		},
		{
			url: APIConfig.approvedDefinitions,
			key: 'definintions'
		},
		{
			url: APIConfig.approvedModels,
			key: 'master'
		}
	]
	
	var listDispatchers = {
		download: {
			url:  APIConfig.downloadComputation,
			preprocessor: function(_obj, data){
				_obj.url = _obj.url + _obj.model_name +'/';
				return _obj;
			}
		}
	}
	
	
	return (
		<React.Fragment>
			<Route path="/ecl-computation/view/:id">
				<ComputationView fetchingData={editFetchingData}/>
			</Route>
			<Route path="/ecl-computation/list">
				<ComputationList fetchingData={listFetchingData} dispatcher={listDispatchers} />
			</Route>
			<Route path="/ecl-computation/create">
				<ComputationCreate fetchingData={createFetcher} dispatcher={createDispatchers}/>
			</Route>
		</React.Fragment>
	);
}

export {
	MainComputationRouter
}
