import React from 'react';
import {config} from '../config/ModelMasterEdit.js';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import {notificationObj} from '../../Global/NotificationHandler.js';
import VariableDataGrid from '../common/VariableDataGrid.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import  { Redirect } from 'react-router-dom';

function ModelMasterEdit(props) {
	
	const [created, setCreated] = React.useState(false);
	const [apiConf, setApiRef] = React.useState({});
	var _data = (props.data || []);
	
	const editClick = React.useCallback(function(){
		
		var errorMsg = '';
		
		var _formData = apiConf.getData()
		
		var obj = {
			... _formData,
			variables: _data[1].map(function(_r){
				if(!errorMsg){
					if((_r.variable_name && !_r.variable_type)){
						errorMsg = 'Enter variable type for defined variable name';
					}else if((!_r.variable_name && _r.variable_type)){
						errorMsg = 'Enter variable name for defined variable type';	
					}

				}
				return {
					variable_id: _r.variable_id,
					variable_name: _r.variable_name,
					variable_type: _r.variable_type
				}
			})
			
		}
		
		
		if(errorMsg){
			notificationObj.showNotification('error',errorMsg);
			return;
		}
		
		props.dispatchRequest('edit', {data: obj} ).then(function(){
			notificationObj.showNotification('success',' Master model edited successfully');
			setCreated(true);
		});
		
	},[props, _data, apiConf]);
	
	if(created){
		return (<Redirect to='/model-master/list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Edit Master Model
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/model-master/list/">
					Master Model (All List)
					</Link>
					<Typography color="textPrimary">{_data[0] && (_data[0].model_name)}</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Master Model
				</Typography>
				<FormBuilder {...config} data={_data[0]} apiConf={apiConf} />
			</Card>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Model Variables
				</Typography>
				
				<div className="ecl-grid-container">
					 <VariableDataGrid  className="variable-grid" rows={_data[1] || []} />
				</div>
			</Card>
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick={editClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ModelMasterEdit);