import React from 'react';
import {config} from '../config/ScenarioDefinitionApprove.js';
import {FormBuilder} from '../../Global/FormBuilder/FormBuilder.js';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import {dataUIWrapper} from '../../Global/dataUIWrapper.js';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import {scenarioYearwiseData} from '../data/scenarioYearwiseData.js';
import ScenarioWeightDataGrid from '../../Global/ScenarioWeightDataGrid.js';
import VariableCurrentMVDataGrid from '../../Global/VariableCurrentMVDataGrid.js';
import ScenarioYearwiseDataGrid from '../../Global/ScenarioYearwiseDataGrid.js';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {notificationObj} from '../../Global/NotificationHandler.js';
import  { Redirect } from 'react-router-dom';

function ScenarioDefinitionApprove(props) {
  
	
	const [apiConf, setApiRef] = React.useState({});
	const [defaultData, setDefaultData] = React.useState({no_of_scenarios: 0, no_of_years:0});
	const [selectedScenerio, setSelectedScenerio] = React.useState(0);
	const [created, setCreated] = React.useState(false);

	var formData = {};
	
	React.useEffect(function(){
		if(props.formData){
			setDefaultData(props.formData);
		}
	},[props.formData]);
	
	
	const handleScenerioChange = React.useCallback(function(e, newValue){
		setSelectedScenerio(newValue);
	},[]);
	
	const ScenerioTabs = React.useMemo(function(){
		var _tabs = [];
		
		for(var i =0;i<defaultData.no_of_scenarios; i++){
			_tabs.push(<Tab label={"Scenario "+(i+1)} key={i}/>);
		}
		
		return (<React.Fragment>
			<AppBar position="static" className="card-tabs">
				<Tabs value={selectedScenerio} onChange={handleScenerioChange} aria-label="simple tabs example">
					{_tabs}
				</Tabs>
			</AppBar>
			<div className="ecl-grid-container">
				<ScenarioYearwiseDataGrid  preventEdit={true} numberOfYears={defaultData.no_of_years} className="scenario-yearwise-grid variable-grid" rows={(props.scenerioYearWiseData?props.scenerioYearWiseData[selectedScenerio]:[])} />
			</div>
		</React.Fragment>)
	},[defaultData]);
	
	const editClick = React.useCallback(function(){
		var _d = apiConf.getData();
		
		if(!_d.approved_user_comment){
			notificationObj.showNotification('error','Approved comments cannot be blank');
			return;
		}
		
		if(!_d.approval_status){
			notificationObj.showNotification('error','Approved comments cannot be blank');
			return;
		}
		
		var obj = {
			"model_definition_name": _d.model_definition_name,
			"scenario_definition_name": _d.scenario_definition_name,
			"approved_user_comment": _d.approved_user_comment,
			"approval_status": _d.approval_status
		}
		
		props.dispatchRequest('approve', {data: obj} ).then(function(e){
			setCreated(true)
		});
	},[props])
	
	var _data = (props.data || []);
	
	if(created){
		return (<Redirect to='/scenario-definition/approve-list/' />)
	}
	
	return (
		<div className="right-section-contents">
			<Typography variant="h4" className="app-header-title  ecl-header-title">
				Approve Scenario Definition
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/scenario-definition/list/">
					Scenario Definition (All List)
					</Link>
					<Typography color="textPrimary">{_data[0] && (_data[0].model_name)}</Typography>
				</Breadcrumbs>
			</Typography>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
				Scenario Definition
				</Typography>
				<FormBuilder {...config} data={defaultData} apiConf={apiConf}/>
			</Card>
			
			<div className="card-flex-column">
				<Card className="main-ui-card">
					<Typography variant="h6" className="app-sub-title ecl-header-title">
						Variable Current MV Values
					</Typography>
					
					<div className="ecl-grid-container">
						 <VariableCurrentMVDataGrid preventEdit={true} className="variable-current-mv-grid variable-grid" rows={(props.variableMVData || [])} />
					</div>
				</Card>

				<Card className="main-ui-card">
					<Typography variant="h6" className="app-sub-title ecl-header-title">
						Scenario Weights
					</Typography>
					
					<div className="ecl-grid-container">
						 <ScenarioWeightDataGrid preventEdit={true} className="scenario-weight-grid variable-grid" rows={(props.scenerioWeights || [])} />
					</div>
				</Card>
			</div>
			<Card className="main-ui-card">
				<Typography variant="h6" className="app-sub-title ecl-header-title">
					Scenario Year Wise
				</Typography>
				{ScenerioTabs}
			</Card>
			<div className="button-bar">
				<Button variant="contained" color="primary" onClick={editClick}>
					Save
				</Button>
			</div>
		</div>
	);
}

export default dataUIWrapper(ScenarioDefinitionApprove);