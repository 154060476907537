var getPrecisionTwo = function(str){
	return ("00"+str).slice(-2);
}

var preprocessors = {
	date: function(value, connector){
		if(!value){
			return value;
		}
		connector = connector || 'T'
		
		var val = new Date(value);
		return val.getFullYear()+'-'+getPrecisionTwo(val.getMonth())+'-'+getPrecisionTwo(val.getDate())+connector+getPrecisionTwo(val.getHours())+':'+getPrecisionTwo(val.getMinutes());
	}
	
}

export default preprocessors;